var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _vm.post.rejectingReason
                ? _c(
                    "el-alert",
                    {
                      staticClass: "mb-20",
                      attrs: {
                        title: "Rejecting reason:",
                        type: "error",
                        closable: false
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.post.rejectingReason) +
                          "\n      "
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "panel",
                { staticClass: "post", attrs: { title: _vm.post.headline } },
                [
                  _c("div", { staticClass: "description" }, [
                    _c("h3", [_vm._v("Description:")]),
                    _vm._v(" "),
                    _c("span", {
                      domProps: { innerHTML: _vm._s(_vm.post.description) }
                    })
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "images" }, [
                    _c("div", { staticClass: "featured-image" }, [
                      _vm.post.image
                        ? _c("img", {
                            attrs: { src: "/files/posts/" + _vm.post.image },
                            on: {
                              click: function($event) {
                                _vm.previewImage = 0
                              }
                            }
                          })
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _vm.extraImages.length
                      ? _c(
                          "div",
                          {
                            staticClass: "extra-images",
                            class: "images-" + _vm.extraImages.length
                          },
                          _vm._l(_vm.extraImages, function(image, index) {
                            return _c(
                              "div",
                              {
                                key: image + index,
                                staticClass: "extra-image"
                              },
                              [
                                _c("img", {
                                  attrs: { src: image },
                                  on: {
                                    click: function($event) {
                                      _vm.previewImage = index + 1
                                    }
                                  }
                                })
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ])
                ]
              ),
              _vm._v(" "),
              _c(
                "panel",
                {
                  attrs: { title: "Offers" },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _vm.post.status === "ACTIVE" &&
                          _vm.post.createdBy.id !== _vm.user.id
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-plus"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.manageOffer = true
                                    }
                                  }
                                },
                                [_vm._v("\n            Make Offer\n          ")]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _vm.postOffers.length
                    ? _c("custom-offers-table", {
                        attrs: {
                          post: _vm.post,
                          offers: _vm.postOffers,
                          "for-posts": ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c(
                "panel",
                {
                  attrs: { title: "Post Info" },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.statusColors[_vm.post.status],
                                effect: "dark",
                                size: "small"
                              }
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("postStatus")(_vm.post.status)
                                  ) +
                                  "\n          "
                              )
                            ]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _c("ul", { staticClass: "gn-list" }, [
                    _c("li", [
                      _c("label", [_vm._v("Post ID:")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.post.id))])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Created At:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.post.createdAt)))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Expiry Date:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm._f("date")(_vm.post.expiringAt)))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Profile:")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "CustomerView",
                                  params: { id: _vm.post.user.id }
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.post.user.uuid) +
                                  " - " +
                                  _vm._s(_vm.post.user.firstName) +
                                  " " +
                                  _vm._s(_vm.post.user.lastName) +
                                  "\n              "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Auto-Published:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(_vm._s(_vm.post.autoPublished ? "Yes" : "No"))
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Post Classification:")]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm._f("classification")(_vm.post.classification)
                          )
                        )
                      ])
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Reviewed At:")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm.post.reviewedAt
                            ? [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(
                                      _vm._f("date")(_vm.post.reviewedAt)
                                    ) +
                                    "\n              "
                                )
                              ]
                            : [_vm._v("\n                N/A\n              ")]
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Reviewed By:")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm.post.reviewedBy
                            ? [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(_vm.post.reviewedBy.firstName) +
                                    " " +
                                    _vm._s(_vm.post.reviewedBy.lastName) +
                                    "\n              "
                                )
                              ]
                            : [_vm._v("\n                N/A\n              ")]
                        ],
                        2
                      )
                    ])
                  ])
                ]
              ),
              _vm._v(" "),
              !_vm.post.reviewedAt && _vm.post.status !== "EXPIRED"
                ? _c("panel", [
                    _c(
                      "div",
                      { staticClass: "items-center" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "small",
                              icon: "el-icon-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateStatus("ACTIVE")
                              }
                            }
                          },
                          [_vm._v("\n            Approve\n          ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              size: "small",
                              icon: "el-icon-close"
                            },
                            on: {
                              click: function($event) {
                                _vm.rejectDrawer = true
                              }
                            }
                          },
                          [_vm._v("\n            Reject\n          ")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.manageOffer
        ? _c("manage-offer", {
            attrs: { "post-id": _vm.postId },
            on: {
              close: function($event) {
                _vm.manageOffer = false
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.rejectDrawer
        ? _c("rejecting-reason", {
            on: {
              close: function($event) {
                _vm.rejectDrawer = false
              },
              reject: function($event) {
                return _vm.updateStatus("REJECTED", $event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("vue-gallery", {
        attrs: { images: _vm.images, index: _vm.previewImage },
        on: {
          close: function($event) {
            _vm.previewImage = null
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }