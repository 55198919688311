var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    { staticClass: "w-full", attrs: { validator: _vm.validator } },
    [
      !_vm.showAsText
        ? _c(
            "el-checkbox",
            _vm._b(
              {
                attrs: { placeholder: _vm.placeholder, disabled: _vm.disabled },
                model: {
                  value: _vm.val,
                  callback: function($$v) {
                    _vm.val = $$v
                  },
                  expression: "val"
                }
              },
              "el-checkbox",
              _vm.options,
              false
            ),
            [_vm._t("default")],
            2
          )
        : _c("span", { staticClass: "textual" }, [
            _vm._v("\n    " + _vm._s(_vm.val || "N/A") + "\n  ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }