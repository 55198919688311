var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { attrs: { id: "nav" } }, [
    _c(
      "div",
      { staticClass: "page-info" },
      [
        _c("div", { staticClass: "title" }, [
          _c("h1", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("h2", [_vm._v(_vm._s(_vm.subtitle))])
        ]),
        _vm._v(" "),
        _c(
          "el-breadcrumb",
          { attrs: { separator: "/" } },
          [
            _vm._l(_vm.breadcrumbs, function(breadcrumb, index) {
              return [
                breadcrumb.to
                  ? _c(
                      "el-breadcrumb-item",
                      { key: index, attrs: { to: breadcrumb.to } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(breadcrumb.text) +
                            "\n        "
                        )
                      ]
                    )
                  : _c("el-breadcrumb-item", { key: index }, [
                      _vm._v(
                        "\n          " + _vm._s(breadcrumb.text) + "\n        "
                      )
                    ])
              ]
            })
          ],
          2
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "actions" },
      [_c("portal-target", { attrs: { name: "actions" } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }