var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gn-drawer",
    {
      attrs: {
        title: _vm.edit ? "Edit Post" : "Add new Post",
        "before-close": _vm.beforeClose,
        size: 500,
        "destroy-on-close": ""
      },
      on: {
        closed: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.loading ? "Saving..." : "Save") +
                      "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _vm.user.admin
            ? _c("gn-select", {
                attrs: {
                  validator: _vm.$v.form.user,
                  options: _vm.profileOptions,
                  label: "Profile",
                  placeholder: "Select profile",
                  filterable: ""
                },
                model: {
                  value: _vm.form.user,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "user", $$v)
                  },
                  expression: "form.user"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("gn-select", {
            attrs: {
              options: _vm.classificationsOptions,
              validator: _vm.$v.form.classification,
              label: "Post Classification",
              placeholder: "Select post classification",
              filterable: ""
            },
            model: {
              value: _vm.form.classification,
              callback: function($$v) {
                _vm.$set(_vm.form, "classification", $$v)
              },
              expression: "form.classification"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.headline,
              maxlength: "50",
              label: "Headline",
              placeholder: "Enter headline"
            },
            model: {
              value: _vm.form.headline,
              callback: function($$v) {
                _vm.$set(_vm.form, "headline", $$v)
              },
              expression: "form.headline"
            }
          }),
          _vm._v(" "),
          _c("gn-textarea", {
            attrs: {
              validator: _vm.$v.form.description,
              maxlength: "1000",
              label: "Description",
              placeholder: "Enter description"
            },
            model: {
              value: _vm.form.description,
              callback: function($$v) {
                _vm.$set(_vm.form, "description", $$v)
              },
              expression: "form.description"
            }
          }),
          _vm._v(" "),
          _c(
            "form-item",
            {
              staticClass: "w-full",
              attrs: { validator: _vm.$v.fakeExpiringAt, label: "Expiring In" }
            },
            [
              _c(
                "div",
                { staticClass: "expiring-at" },
                [
                  _c(
                    "div",
                    { staticClass: "mr-10" },
                    [
                      _c("gn-select", {
                        attrs: {
                          validator: _vm.$v.expiringAt.type,
                          options: _vm.expiringTypeOptions
                        },
                        model: {
                          value: _vm.expiringAt.type,
                          callback: function($$v) {
                            _vm.$set(_vm.expiringAt, "type", $$v)
                          },
                          expression: "expiringAt.type"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.expiringAt.value,
                      options: _vm.expiringOptions
                    },
                    model: {
                      value: _vm.expiringAt.value,
                      callback: function($$v) {
                        _vm.$set(_vm.expiringAt, "value", $$v)
                      },
                      expression: "expiringAt.value"
                    }
                  })
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("gn-upload-input", {
            attrs: { label: "Featured Image", "only-images": "" },
            model: {
              value: _vm.image,
              callback: function($$v) {
                _vm.image = $$v
              },
              expression: "image"
            }
          }),
          _vm._v(" "),
          _vm._l(_vm.extraImages, function(extraImg, index) {
            return _c(
              "div",
              { key: index, staticClass: "extra-images" },
              [
                _c("gn-upload-input", {
                  staticClass: "upload",
                  attrs: {
                    label: "Extra Image " + (index + 1),
                    "only-images": ""
                  },
                  model: {
                    value: extraImg.file,
                    callback: function($$v) {
                      _vm.$set(extraImg, "file", $$v)
                    },
                    expression: "extraImg.file"
                  }
                }),
                _vm._v(" "),
                _c("span", {
                  staticClass: "remove el-icon-close",
                  on: {
                    click: function($event) {
                      return _vm.removeExtraImage(index)
                    }
                  }
                })
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.extraImages.length < _vm.maxExtraImages
            ? _c(
                "el-link",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.addExtraImage()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      Add Extra Image (" +
                      _vm._s(_vm.extraImages.length) +
                      " / " +
                      _vm._s(_vm.maxExtraImages) +
                      ")\n    "
                  )
                ]
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }