var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "image-panel",
    {
      attrs: {
        title: "Give and Get",
        subtitle: "Stuff exchange platform.",
        image: "assets/images/account-verify.svg"
      }
    },
    [
      _c("section", { staticClass: "content-wrapper" }, [
        _c("h1", { staticClass: "gn-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("h2", { staticClass: "gn-subtitle" }, [_vm._v(_vm._s(_vm.text))]),
        _vm._v(" "),
        _c(
          "a",
          { attrs: { href: "giveandgetapp://" } },
          [
            _c(
              "el-button",
              { staticClass: "button", attrs: { type: "primary", round: "" } },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.error ? "Back to Login" : "Login") +
                    "\n      "
                )
              ]
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }