var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    { attrs: { label: _vm.label, validator: _vm.validator } },
    [
      !_vm.showAsText
        ? _c(
            "el-select",
            {
              staticClass: "w-full",
              attrs: {
                disabled: _vm.disabled,
                placeholder: _vm.placeholder,
                clearable: _vm.clearable,
                filterable: _vm.filterable
              },
              model: {
                value: _vm.val,
                callback: function($$v) {
                  _vm.val = $$v
                },
                expression: "val"
              }
            },
            _vm._l(_vm.options, function(option) {
              return _c("el-option", {
                key: option.id,
                attrs: {
                  disabled: option.disabled,
                  label: option.text,
                  value: option.id
                }
              })
            }),
            1
          )
        : _c("span", { staticClass: "textual" }, [
            _vm._v("\n    " + _vm._s(_vm.textualValue) + "\n  ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }