const state = {
  title: null,
  subtitle: null,
  breadcrumbs: []
}

const mutations = {
  setPageInfo (state, data) {
    const title = data.title ? `${data.title} - ${window.appData.title}` : window.appData.title
    document.title = title
    state.title = data.title || null
    state.subtitle = data.subtitle || null
    state.breadcrumbs = data.breadcrumbs || []
  }
}

export default {
  state,
  mutations,
  namespaced: true
}