var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table", { staticClass: "gn-table" }, [
    _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Offer ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Profile ID")]),
        _vm._v(" "),
        _c("th", [_vm._v("Featured Image")]),
        _vm._v(" "),
        _c("th", [_vm._v("Distance")]),
        _vm._v(" "),
        _c("th", [_vm._v("Headline")]),
        _vm._v(" "),
        _c("th", [_vm._v("Offer Classification")]),
        _vm._v(" "),
        _c("th", { class: { "text-right": !_vm.showActions } }, [
          _vm._v("Offer Made At")
        ]),
        _vm._v(" "),
        _vm.showActions
          ? _c("th", { staticClass: "text-right" }, [_vm._v("Actions")])
          : _vm._e()
      ])
    ]),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.offers, function(offer) {
        return _c("custom-offers-table-row", {
          key: offer.id,
          attrs: {
            post: _vm.post,
            offer: offer,
            "show-actions": _vm.showActions
          }
        })
      }),
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }