var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-drawer",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.drawer,
        "before-close": _vm.beforeClose,
        size: _vm.size,
        "destroy-on-close": ""
      },
      on: {
        "update:visible": function($event) {
          _vm.drawer = $event
        },
        close: function($event) {
          return _vm.$emit("closed")
        }
      }
    },
    [
      _c("div", { staticClass: "content" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _vm.$slots.footer
        ? _c("div", { staticClass: "drawer__footer" }, [_vm._t("footer")], 2)
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }