<template>
  <gn-drawer
    v-model="drawer"
    :title="edit ? 'Edit Post' : 'Add new Post'"
    :before-close="beforeClose"
    :size="500"
    destroy-on-close
    @closed="$emit('close')">
    <el-form label-position="top">
      <gn-select
        v-if="user.admin"
        v-model="form.user"
        :validator="$v.form.user"
        :options="profileOptions"
        label="Profile"
        placeholder="Select profile"
        filterable />

      <gn-select
        v-model="form.classification"
        :options="classificationsOptions"
        :validator="$v.form.classification"
        label="Post Classification"
        placeholder="Select post classification"
        filterable />

      <gn-input
        v-model="form.headline"
        :validator="$v.form.headline"
        maxlength="50"
        label="Headline"
        placeholder="Enter headline" />

      <gn-textarea
        v-model="form.description"
        :validator="$v.form.description"
        maxlength="1000"
        label="Description"
        placeholder="Enter description" />

      <form-item
        :validator="$v.fakeExpiringAt"
        label="Expiring In"
        class="w-full">
        <div class="expiring-at">
          <div class="mr-10">
            <gn-select
              v-model="expiringAt.type"
              :validator="$v.expiringAt.type"
              :options="expiringTypeOptions" />
          </div>

          <gn-select
            v-model="expiringAt.value"
            :validator="$v.expiringAt.value"
            :options="expiringOptions" />
        </div>
      </form-item>

      <gn-upload-input
        v-model="image"
        label="Featured Image"
        only-images />

      <div
        v-for="(extraImg, index) in extraImages"
        :key="index"
        class="extra-images">
        <gn-upload-input
          v-model="extraImg.file"
          :label="`Extra Image ${index + 1}`"
          class="upload"
          only-images />

        <span
          class="remove el-icon-close"
          @click="removeExtraImage(index)" />
      </div>
    
      <el-link
        v-if="extraImages.length < maxExtraImages"
        type="primary"
        @click="addExtraImage()">
        Add Extra Image ({{extraImages.length}} / {{ maxExtraImages }})
      </el-link>
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import FormItem from '../../common/ui/forms/FormItem'

export default {
  components: {
    FormItem
  },

  props: {
    edit: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      form: {
        user: null,
        headline: null,
        description: null,
        classification: null
      },
      expiringAt: {
        type: 'day',
        value: null
      },
      fakeExpiringAt: null,

      expiringTypeOptions: [
        {
          id: 'day',
          text: 'Days'
        },
        {
          id: 'hour',
          text: 'Hours'
        }
      ],

      classificationsOptions: window.classificationsOptions,

      maxExtraImages: 4,

      image: null,
      extraImages: [],

      loading: false,
      drawer: true
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    },

    user () {
      return this.$store.state.user.user
    },

    expiringOptions () {
      let postfix = 'hour'
      let options = [1, 2, 6, 12, 24]

      if (this.expiringAt.type === 'day') {
        postfix = 'day'
        options = [1, 2, 3, 4, 5, 6, 7]
      }

      return options.map(option => ({
        id: option,
        text: option === 1 ? `${option} ${postfix}` : `${option} ${postfix}s`
      }))
    },

    profileOptions () {
      return this.$store.getters['users/list'].filter(user => !user.admin).map(user => ({
        id: user.id,
        text: `${user.uuid} - ${user.firstName} ${user.lastName}`
      }))
    }
  },

  watch: {
    'expiringAt.type' () {
      this.expiringAt.value = null
    },

    expiringAt: {
      handler (expiringAt) {
        // This code here sets fakeExpiringAt to something if both type and value are set.
        // This is done because of validation of two selects and only one label. This way label can show that it is requred.
        this.fakeExpiringAt = expiringAt.type && expiringAt.value ? 'COMPLETE' : null
      },
      deep: true
    }
  },

  created () {
    if (this.edit) {
      this.form.firstName = this.edit.firstName
      this.form.lastName = this.edit.lastName
      this.form.email = this.edit.email
    } else {
      if (!this.user.admin) {
        this.form.user = this.user.id
      }
      this.addExtraImage()
    }
  },

  methods: {
    addExtraImage () {
      this.extraImages.push({ file: null })
    },

    removeExtraImage (index) {
      this.extraImages.splice(index, 1)
    },

    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true
      try {
        const postStatuses = {
          'YES': 'ACTIVE',
          'NO': 'PENDING',
          'VERIFIED_ONLY': this.user.status === 'VERIFIED' ? 'ACTIVE' : 'PENDING'
        }

        const status = postStatuses[this.system.post_auto_push]

        const payload = this.edit ? { id: this.edit.id, payload: this.form } : {
          ...this.form,
          status,
          createdAt: 'CURRENT_TIMESTAMP',
          autoPublished: status === 'ACTIVE',
          createdBy: this.user.id
        }

        const action = this.edit ? 'posts/update' : 'posts/create'
        const post = await this.$store.dispatch(action, payload)
        await this.$store.dispatch('posts/action', {
          id: post.id,
          action: 'setExpiration',
          payload: this.expiringAt
        })
        
        if (this.image) await this.$store.dispatch('posts/upload', { id: post.id, file: this.image })
        if (this.extraImages.length) {
          const promises = this.extraImages.map((extra, index) => this.$store.dispatch('posts/upload', {
            id: post.id,
            file: extra.file,
            params: { field: `image${index + 2}` }
          }))
          await Promise.all(promises)
        }
        this.$message.success(`Post successfully ${this.edit ? 'updated' : 'created'}.`)
        this.clear()
        this.$emit('close')
      } catch {
      } finally {
        this.loading = false
      }
    },

    async beforeClose (done) {
      if (Object.values(this.form).every(val => !val)) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },

    clear () {
      this.form = {
        user: null,
        headline: null,
        description: null
      }
      this.expiringAt = {
        type: 'day',
        value: null
      }
      this.image = null
      this.extraImages = []
      this.$v.$reset()
    }
  },

  validations () {
    const rules = {
      form: {
        user: { required },
        headline: { required },
        description: { required },
        classification: { required },
      },
      fakeExpiringAt: { required },
      expiringAt: {
        type: { required },
        value: { required }
      }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.extra-images {
  width: 100%;
  display: flex;
  align-items: end;
  .remove {
    display: inline-block;
    margin: 5px 0 5px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  .upload {
    width: 100%;
  }
}

.expiring-at {
  display: flex;
}
</style>
