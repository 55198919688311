var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "default", icon: "el-icon-s-operation" },
              on: {
                click: function($event) {
                  _vm.openFilters = true
                }
              }
            },
            [_vm._v("\n      Filters\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("navigation", { attrs: { menu: _vm.menu } }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "applied-filters" },
        _vm._l(_vm.appliedFilters, function(filter) {
          return _c(
            "el-tag",
            {
              key: filter.key,
              staticClass: "applied-filter",
              attrs: {
                type: "primary",
                effect: "dark",
                size: "small",
                closable: ""
              },
              on: {
                close: function($event) {
                  return _vm.removeFilter(filter)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(filter.text) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Customers" } },
        [
          _c(
            "el-table",
            { attrs: { data: _vm.filteredCustomers } },
            [
              _c("el-table-column", {
                attrs: { label: "Profile ID", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "CustomerView",
                                params: { id: scope.row.id }
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(scope.row.uuid) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Created At" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.createdAt)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "First Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.firstName) +
                            "\n          "
                        ),
                        scope.row.status === "VERIFIED"
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: { content: "Verified", placement: "top" }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "verified-profile-badge" },
                                  [_c("span", { staticClass: "el-icon-check" })]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Last Name" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.lastName) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Email" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.email) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Verified" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm.verificationStatus(scope.row)
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: _vm.verificationStatus(scope.row),
                                  placement: "top"
                                }
                              },
                              [
                                _c("span", {
                                  staticClass: "status-icon",
                                  class: _vm.verificationIcon(scope.row)
                                })
                              ]
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Status", width: "200", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.statusColor[scope.row.status],
                              effect: "dark",
                              size: "mini"
                            }
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("status")(scope.row.status)) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.openFilters
        ? _c("customers-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }