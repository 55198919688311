<template>
  <panel>
    <div class="profile-info">
      <router-link :to="customerProfileRoute">
        <img
          class="avatar"
          src="assets/images/avatar.png">
      </router-link>
      <div>
        <router-link :to="customerProfileRoute">
          <span class="name">
            {{ profile.firstName || '' }} {{ profile.lastName || '' }}
            <el-tag
              :type="statusColors[profile.status]"
              effect="dark"
              size="mini"
              class="status">
              {{ profile.status | status }}
            </el-tag>
          </span>
        </router-link>
        <span class="email">{{ profile.email }}</span>
      </div>
    </div>
  </panel>
</template>

<script>
import avatar from '../../../images/avatar.png'

export default {
  props: {
    profile: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      avatar
    }
  },

  computed: {
    statusColors () {
      return window.userStatusColors
    },

    customerProfileRoute () {
      return { name: 'CustomerView', params: { id: this.profile.id } }
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-info {
  display: flex;
  align-items: center;
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    object-fit: cover;
    margin-right: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: bold;
    color: #091520;
    &:hover {
      color: #409EFF;
    }
  }
  .email {
    display: block;
    font-size: 15px;
    color: #5a738a;
    margin-top: 3px;
  }
  .status {
    margin-left: 5px;
  }
}
</style>
