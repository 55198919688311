var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  _vm.manage = true
                }
              }
            },
            [_vm._v("\n      Add new Change Log\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Change Log" } },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.changelogs } },
            [
              _c("el-table-column", {
                attrs: { label: "Version", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.version) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Description" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(scope.row.description) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Date/Time", width: "250" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("date")(scope.row.createdAt)) +
                            "\n        "
                        )
                      ]
                    }
                  }
                ])
              }),
              _vm._v(" "),
              _c("el-table-column", {
                attrs: { label: "Actions", width: "100", align: "right" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top", content: "Edit" } },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "default",
                                icon: "el-icon-edit",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.openEdit(scope.row)
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-tooltip",
                          { attrs: { placement: "top", content: "Delete" } },
                          [
                            _c("el-button", {
                              attrs: {
                                size: "mini",
                                type: "danger",
                                icon: "el-icon-delete",
                                circle: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.remove(scope.row.id)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gn-drawer",
        {
          attrs: {
            title: "Add new Change Log",
            "before-close": _vm.beforeClose
          },
          on: {
            closed: function($event) {
              return _vm.onClose()
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      on: {
                        click: function($event) {
                          _vm.manage = false
                        }
                      }
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      attrs: { type: "primary", loading: _vm.loading },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.loading ? "Saving..." : "Save") +
                          "\n      "
                      )
                    ]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.manage,
            callback: function($$v) {
              _vm.manage = $$v
            },
            expression: "manage"
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c("gn-input", {
                attrs: {
                  validator: _vm.$v.form.version,
                  label: "Version",
                  placeholder: "Enter Version"
                },
                model: {
                  value: _vm.form.version,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "version", $$v)
                  },
                  expression: "form.version"
                }
              }),
              _vm._v(" "),
              _c("gn-textarea", {
                attrs: {
                  validator: _vm.$v.form.description,
                  label: "Description",
                  placeholder: "Enter description"
                },
                model: {
                  value: _vm.form.description,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "description", $$v)
                  },
                  expression: "form.description"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }