<template>
  <div class="panel">
    <div
      v-if="title"
      class="header">
      <h3>{{ title }}</h3>
      
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>
    <div v-if="$slots.default" class="content">
      <slot />
    </div>
    <div
      v-if="$slots.footer"
      class="footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    }
  }
}
</script>
