var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("panel", [
    _c(
      "div",
      { staticClass: "profile-info" },
      [
        _c("router-link", { attrs: { to: _vm.customerProfileRoute } }, [
          _c("img", {
            staticClass: "avatar",
            attrs: { src: "assets/images/avatar.png" }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("router-link", { attrs: { to: _vm.customerProfileRoute } }, [
              _c(
                "span",
                { staticClass: "name" },
                [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.profile.firstName || "") +
                      " " +
                      _vm._s(_vm.profile.lastName || "") +
                      "\n          "
                  ),
                  _c(
                    "el-tag",
                    {
                      staticClass: "status",
                      attrs: {
                        type: _vm.statusColors[_vm.profile.status],
                        effect: "dark",
                        size: "mini"
                      }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm._f("status")(_vm.profile.status)) +
                          "\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "email" }, [
              _vm._v(_vm._s(_vm.profile.email))
            ])
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }