var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          _vm.customer.disapprovalReasons.length
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", icon: "el-icon-time" },
                  on: {
                    click: function($event) {
                      _vm.showDisapprovalReasons = true
                    }
                  }
                },
                [_vm._v("\n      Verification History\n    ")]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { offset: 6, span: 12 } },
            [
              _vm.customer.disapprovalReason
                ? _c("el-alert", {
                    staticClass: "mb-20",
                    attrs: {
                      title: "Disapproval reason:",
                      description: _vm.customer.disapprovalReason.text,
                      type: "error",
                      closable: false
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("profile-info", { attrs: { profile: _vm.customer } }),
              _vm._v(" "),
              _c(
                "panel",
                { attrs: { title: "Contact Information" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("gn-select", {
                            attrs: {
                              validator: _vm.$v.form.sex,
                              options: _vm.sexOptions,
                              label: "Sex",
                              placeholder: "What's your sex",
                              filterable: "",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.sex,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "sex", $$v)
                              },
                              expression: "form.sex"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("gn-date-picker", {
                            attrs: {
                              validator: _vm.$v.form.dob,
                              "picker-options": _vm.pickerOptions,
                              type: "date",
                              label: "Date of Birth",
                              placeholder: "Select Date of Birth"
                            },
                            model: {
                              value: _vm.form.dob,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "dob", $$v)
                              },
                              expression: "form.dob"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.ssn,
                              label: "SSN",
                              placeholder: "Enter SSN"
                            },
                            model: {
                              value: _vm.form.ssn,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "ssn", $$v)
                              },
                              expression: "form.ssn"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.phone,
                              label: "Phone Number",
                              placeholder: "Enter Phone Number"
                            },
                            model: {
                              value: _vm.form.phone,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "phone", $$v)
                              },
                              expression: "form.phone"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.mobile,
                              label: "Mobile Number",
                              placeholder: "Enter Mobile Number"
                            },
                            model: {
                              value: _vm.form.mobile,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "mobile", $$v)
                              },
                              expression: "form.mobile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "panel",
                { attrs: { title: "Address Information" } },
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.address,
                              label: "Address",
                              placeholder: "Enter Address"
                            },
                            model: {
                              value: _vm.form.address,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address", $$v)
                              },
                              expression: "form.address"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.address2,
                              label: "Address 2",
                              placeholder: "Enter Address 2"
                            },
                            model: {
                              value: _vm.form.address2,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "address2", $$v)
                              },
                              expression: "form.address2"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-select", {
                            attrs: {
                              validator: _vm.$v.form.country,
                              options: _vm.countryOptions,
                              label: "Country",
                              filterable: ""
                            },
                            model: {
                              value: _vm.form.country,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "country", $$v)
                              },
                              expression: "form.country"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-select", {
                            attrs: {
                              validator: _vm.$v.form.state,
                              disabled: !_vm.countryHasState,
                              options: _vm.stateOptions,
                              label: "Province/State",
                              filterable: ""
                            },
                            model: {
                              value: _vm.form.state,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "state", $$v)
                              },
                              expression: "form.state"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.city,
                              label: "City",
                              placeholder: "Enter City"
                            },
                            model: {
                              value: _vm.form.city,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "city", $$v)
                              },
                              expression: "form.city"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _c("gn-input", {
                            attrs: {
                              validator: _vm.$v.form.zip,
                              label: "ZIP / Postal Code",
                              placeholder: "Enter ZIP / Postal Code"
                            },
                            model: {
                              value: _vm.form.zip,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "zip", $$v)
                              },
                              expression: "form.zip"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "panel",
                {
                  attrs: { title: "Identification" },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _vm.idTypes.length < _vm.idTypeOptions.length
                            ? _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-plus"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.addId()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Add another ID\n          "
                                  )
                                ]
                              )
                            : _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "info",
                                    size: "small",
                                    icon: "el-icon-close"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.removeId()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            Remove last ID\n          "
                                  )
                                ]
                              )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _vm._l(_vm.idTypes, function(idType, index) {
                    return _c(
                      "el-row",
                      { key: index, attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("gn-select", {
                              attrs: {
                                validator: _vm.$v.idTypes.$each[index].type,
                                options: _vm.getIdTypeOptions(
                                  _vm.idTypes[index].type
                                ),
                                label: "Government Issued ID Type ",
                                placeholder:
                                  "Select Government Issued ID Type ",
                                filterable: "",
                                clearable: ""
                              },
                              model: {
                                value: idType.type,
                                callback: function($$v) {
                                  _vm.$set(idType, "type", $$v)
                                },
                                expression: "idType.type"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("gn-input", {
                              attrs: {
                                validator: _vm.$v.idTypes.$each[index].number,
                                label: "Government Issued ID Number",
                                placeholder: "Enter Government Issued ID Number"
                              },
                              model: {
                                value: idType.number,
                                callback: function($$v) {
                                  _vm.$set(idType, "number", $$v)
                                },
                                expression: "idType.number"
                              }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c("gn-upload-input", {
                              attrs: {
                                validator: _vm.$v.idTypes.$each[index].document,
                                label: idType.type
                                  ? _vm.getIdOptionText(idType.type)
                                  : "Document"
                              },
                              on: {
                                input: function($event) {
                                  _vm.idTypes[index].changed = true
                                }
                              },
                              model: {
                                value: _vm.idTypes[index].document,
                                callback: function($$v) {
                                  _vm.$set(_vm.idTypes[index], "document", $$v)
                                },
                                expression: "idTypes[index].document"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                ],
                2
              ),
              _vm._v(" "),
              _c("panel", [
                _c(
                  "div",
                  { staticClass: "items-center" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.profileUrl } },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "default",
                              size: "small",
                              icon: "el-icon-close",
                              disabled: _vm.loading
                            }
                          },
                          [_vm._v("\n              Cancel\n            ")]
                        )
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        staticClass: "ml-10",
                        attrs: {
                          type: _vm.$v.$invalid ? "primary" : "success",
                          size: "small",
                          icon: "el-icon-check",
                          loading: _vm.loading
                        },
                        on: {
                          click: function($event) {
                            return _vm.submit()
                          }
                        }
                      },
                      [_vm._v("\n            Submit\n          ")]
                    )
                  ],
                  1
                )
              ])
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Disapproval Reasons",
            visible: _vm.showDisapprovalReasons,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDisapprovalReasons = $event
            }
          }
        },
        [
          _c(
            "ul",
            { staticClass: "gn-list" },
            _vm._l(_vm.customer.disapprovalReasons, function(reason) {
              return _c("li", { key: reason.id }, [
                _c("label", [
                  _vm._v(
                    "Disapproval Reason from " +
                      _vm._s(_vm._f("date")(reason.createdAt))
                  )
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(reason.text))])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showDisapprovalReasons = false
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }