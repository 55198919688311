var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("tr", [
    _c(
      "td",
      [
        _c("router-link", { attrs: { to: _vm.offerUrl } }, [
          _vm._v("\n      Offer - " + _vm._s(_vm.offer.id) + "\n    ")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _c(
          "router-link",
          {
            attrs: {
              to: { name: "CustomerView", params: { id: _vm.offer.user.id } },
              target: "_blank"
            }
          },
          [_vm._v("\n      " + _vm._s(_vm.offer.user.uuid) + "\n    ")]
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "td",
      [
        _vm.offer.image
          ? _c(
              "a",
              {
                attrs: {
                  href: "/files/offers/" + _vm.offer.image,
                  target: "_blank"
                }
              },
              [
                _c(
                  "el-image",
                  {
                    staticClass: "featured-image",
                    attrs: {
                      src: "/files/offers/" + _vm.offer.image,
                      fit: "contain"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "image-slot with-icon",
                        attrs: { slot: "error" },
                        slot: "error"
                      },
                      [_c("i", { staticClass: "el-icon-picture-outline" })]
                    )
                  ]
                )
              ],
              1
            )
          : _c("el-image", { staticClass: "featured-image" }, [
              _c(
                "div",
                {
                  staticClass: "image-slot",
                  attrs: { slot: "error" },
                  slot: "error"
                },
                [_vm._v("No image")]
              )
            ])
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("distance")(_vm.getDistanceFromMe(_vm.offer))) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "td",
      [
        _c("router-link", { attrs: { to: _vm.offerUrl } }, [
          _c("b", [_vm._v(_vm._s(_vm.offer.headline))])
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("td", [
      _vm._v(
        "\n    " +
          _vm._s(_vm._f("classification")(_vm.offer.classification)) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _c(
      "td",
      { class: { "text-right": !_vm.showActions } },
      [
        _vm.postOffer.createdAt
          ? [
              _vm._v(
                "\n      " +
                  _vm._s(_vm._f("date")(_vm.postOffer.createdAt)) +
                  "\n    "
              )
            ]
          : [_vm._v("\n      N/A\n    ")]
      ],
      2
    ),
    _vm._v(" "),
    _vm.showActions
      ? _c(
          "td",
          { staticClass: "text-right" },
          [
            _vm.offer.status === "ACTIVE" && _vm.postOffer.status === "ACTIVE"
              ? [
                  _vm.hasPermissions
                    ? [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "success",
                              size: "mini",
                              icon: "el-icon-check"
                            },
                            on: {
                              click: function($event) {
                                return _vm.askAccept()
                              }
                            }
                          },
                          [_vm._v("\n          Accept\n        ")]
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "danger",
                              size: "mini",
                              icon: "el-icon-close"
                            },
                            on: {
                              click: function($event) {
                                return _vm.deny()
                              }
                            }
                          },
                          [_vm._v("\n          Deny\n        ")]
                        )
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.offer.user.id === _vm.user.id &&
                  _vm.postOffer.status === "PENDING"
                    ? _c(
                        "el-button",
                        {
                          attrs: {
                            type: "default",
                            size: "mini",
                            icon: "el-icon-close"
                          },
                          on: {
                            click: function($event) {
                              return _vm.cancel()
                            }
                          }
                        },
                        [_vm._v("\n        Cancel\n      ")]
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ],
          2
        )
      : _vm._e(),
    _vm._v(" "),
    _vm.offer.status === "PENDING"
      ? _c(
          "td",
          { staticClass: "tr-overlay" },
          [
            _vm.user.admin
              ? _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "OfferView", params: { id: _vm.offer.id } },
                      target: "_blank"
                    }
                  },
                  [_c("span", [_vm._v("This offer is awaiting approval.")])]
                )
              : _c("span", [_vm._v("This offer is awaiting approval.")])
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }