var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  _vm.manage = true
                }
              }
            },
            [_vm._v("\n      Add new Offer\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "default", icon: "el-icon-s-operation" },
              on: {
                click: function($event) {
                  _vm.openFilters = true
                }
              }
            },
            [_vm._v("\n      Filters\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-between mb-20" },
        [
          _c(
            "div",
            { staticClass: "applied-filters" },
            _vm._l(_vm.appliedFilters, function(filter) {
              return _c(
                "el-tag",
                {
                  key: filter.key,
                  staticClass: "applied-filter",
                  attrs: {
                    type: "primary",
                    effect: "dark",
                    size: "small",
                    closable: ""
                  },
                  on: {
                    close: function($event) {
                      return _vm.removeFilter(filter)
                    }
                  }
                },
                [_vm._v("\n        " + _vm._s(filter.text) + "\n      ")]
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-button-group",
            [
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: _vm.savedOffers ? "default" : "primary",
                    disabled: !_vm.savedOffers
                  },
                  on: {
                    click: function($event) {
                      _vm.savedOffers = false
                    }
                  }
                },
                [_vm._v("\n        Pinned\n      ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: _vm.savedOffers ? "primary" : "default",
                    disabled: _vm.savedOffers
                  },
                  on: {
                    click: function($event) {
                      _vm.savedOffers = true
                    }
                  }
                },
                [_vm._v("\n        Saved\n      ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        {
          attrs: { title: (!_vm.savedOffers ? "Pinned" : "Saved") + " Offers" }
        },
        [_c("offers-table", { attrs: { offers: _vm.filteredOffers } })],
        1
      ),
      _vm._v(" "),
      _vm.manage
        ? _c("manage-offer", {
            on: {
              close: function($event) {
                return _vm.closeManage()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openFilters
        ? _c("offers-filters", {
            attrs: { filters: _vm.filters, "saved-offers": _vm.savedOffers },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }