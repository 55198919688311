<template>
  <gn-drawer
    v-model="drawer"
    :title="postId ? 'Make Offer' : 'Add new Offer'"
    :before-close="beforeClose"
    :size="500"
    destroy-on-close
    @closed="$emit('close')">
    <el-form label-position="top">
      <gn-select
        v-if="user.admin"
        v-model="form.user"
        :validator="$v.form.user"
        :options="profileOptions"
        label="Profile"
        placeholder="Select profile"
        filterable />

      <gn-select
        v-model="form.classification"
        :options="classificationsOptions"
        :validator="$v.form.classification"
        label="Offer Classification"
        placeholder="Select offer classification"
        filterable />

      <template v-if="postId">
        <gn-select
          v-model="form.offerId"
          :validator="$v.form.offerId"
          :options="offerOptions"
          label="Offer Options"
          placeholder="Select Offer Options"
          filterable />

        <gn-checkbox
          v-if="form.offerId === 'NEW'"
          v-model="form.saved">
          Save to my offers
        </gn-checkbox>
      </template>

      <template v-if="!postId || form.offerId === 'NEW'">
        <gn-input
          v-model="form.headline"
          :validator="$v.form.headline"
          maxlength="50"
          label="Headline"
          placeholder="Enter headline" />

        <gn-textarea
          v-model="form.description"
          :validator="$v.form.description"
          maxlength="1000"
          label="Description"
          placeholder="Enter description" />

        <gn-upload-input
          v-model="image"
          label="Featured Image"
          only-images />

        <div
          v-for="(extraImg, index) in extraImages"
          :key="index"
          class="extra-images">
          <gn-upload-input
            v-model="extraImg.file"
            :label="`Extra Image ${index + 1}`"
            class="upload"
            only-images />

          <span
            class="remove el-icon-close"
            @click="removeExtraImage(index)" />
        </div>
      
        <el-link
          v-if="extraImages.length < maxExtraImages"
          type="primary"
          @click="addExtraImage()">
          Add Extra Image ({{extraImages.length}} / {{ maxExtraImages }})
        </el-link>
      </template>

      <template v-if="form.offerId !== 'NEW' && selectedOffer">
        <ul class="gn-list mt-20">
          <li
            v-for="item, index in offerData"
            :key="index">
            <label>{{ item.label }}:</label>
            <span>{{ item.value }}</span>
          </li>
          <li>
            <label>Featured Image:</label>
            <span>
              <template v-if="selectedOffer.image">
                <img
                  :src="`/files/offers/${selectedOffer.image}`"
                  class="image" />
              </template>
              <template v-else>N/A</template>
            </span>
          </li>
          <li
            v-for="image, index in selectedExtraImages"
            :key="index">
            <label>Extra Image {{ index + 1 }}:</label>
            <span>
              <img
                :src="image"
                class="image" />
            </span>
          </li>
        </ul>
      </template>
    </el-form>

    <template #footer>
      <el-button
        class="button"
        @click="$emit('close')">
        Cancel
      </el-button>
      <el-button
        class="button"
        type="primary"
        :loading="loading"
        @click="save()">
        {{ loading ? 'Saving...' : 'Save' }}
      </el-button>
    </template>
  </gn-drawer>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import FormItem from '../../common/ui/forms/FormItem'

export default {
  components: {
    FormItem
  },

  props: {
    postId: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      form: {
        user: null,
        classification: null,
        offerId: null,
        saved: false,
        headline: null,
        description: null
      },

      classificationsOptions: window.classificationsOptions,

      maxExtraImages: 4,
      image: null,
      extraImages: [],

      loading: false,
      drawer: true
    }
  },

  computed: {
    selectedOffer () {
      return this.form.offerId ? this.offers.find(offer => offer.id === this.form.offerId) : null
    },

    selectedExtraImages () {
      if (!this.selectedOffer) return []
      
      const imageKeys = ['image2', 'image3', 'image4', 'image5']
      return imageKeys.map(key => this.selectedOffer[key]).filter(image => image).map(image => `/files/offers/${image}`)
    },

    offerData () {
      if (!this.selectedOffer) return null

      return [
        {
          label: 'Headline',
          value: this.selectedOffer.headline
        },
        {
          label: 'Description',
          value: this.selectedOffer.description
        },
      ]
    },

    offers () {
      return this.$store.getters['offers/list']
    },

    offerOptions () {
      const offers = this.offers.filter(offer => offer.postOffers.every(postOffer => parseInt(postOffer.postId) !== this.postId) && offer.classification === this.form.classification)
      return [
        {
          id: 'NEW',
          text: 'New'
        },
        ...offers.map(offer => ({
          id: offer.id,
          text: `${offer.id} - ${offer.headline}`
        }))
      ]
    },

    system () {
      return this.$store.getters['system/get'](1)
    },

    user () {
      return this.$store.state.user.user
    },

    profileOptions () {
      return this.$store.getters['users/list'].filter(user => !user.admin).map(user => ({
        id: user.id,
        text: `${user.uuid} - ${user.firstName} ${user.lastName}`
      }))
    }
  },

  created () {
    if (!this.user.admin) {
      this.form.user = this.user.id
    }
    this.form.saved = !this.postId
    this.addExtraImage()
  },

  methods: {
    addExtraImage () {
      this.extraImages.push({ file: null })
    },

    removeExtraImage (index) {
      this.extraImages.splice(index, 1)
    },

    async save () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.loading = true
      try {
        const offerToUpdate = (this.form.offerId !== 'NEW' && this.form.offerId) || null
        let offer = await (offerToUpdate ? this.$store.dispatch('offers/get', offerToUpdate) : this.createOffer())
        if (this.postId) offer = await this.addOfferToPost(offer)
        
        if (this.image) await this.$store.dispatch('offers/upload', { id: offer.id, file: this.image })
        if ((!this.postId || this.form.offerId === 'NEW') && this.extraImages.length) {
          const promises = this.extraImages.filter(image => image.file).map((extra, index) => this.$store.dispatch('offers/upload', {
            id: offer.id,
            file: extra.file,
            params: { field: `image${index + 2}` }
          }))
          await Promise.all(promises)
        }

        this.$message.success(this.postId && this.form.offerId ? 'You successfully made an offer.' : 'Offer successfully created')
        this.clear()
        this.$emit('close')
      } catch {
      } finally {
        this.loading = false
      }
    },

    async addOfferToPost (offer) {
      const postOffers = offer.postOffers || []
      const payload = {
        id: offer.id,
        payload: {
          postOffers: [
            ...postOffers,
            {
              postId: this.postId,
              offerId: offer.id,
              createdAt: 'CURRENT_TIMESTAMP'
            }
          ]
        }
      }
      return this.$store.dispatch('offers/update', payload)

    },

    createOffer () {
      const offerStatuses = {
        'YES': 'ACTIVE',
        'NO': 'PENDING',
        'VERIFIED_ONLY': this.user.status === 'VERIFIED' ? 'ACTIVE' : 'PENDING'
      }
      const status = offerStatuses[this.system.offer_auto_push]

      const payload = {
        ...this.form,
        status,
        createdAt: 'CURRENT_TIMESTAMP',
        autoPublished: status === 'ACTIVE',
        createdBy: this.user.id
      }

      return this.$store.dispatch('offers/create', payload)
    },

    async beforeClose (done) {
      if (Object.values(this.form).every(val => !val)) return done()

      try {
        await this.$confirm('Are you sure you want to close this?')
        done()
      } catch (e) {
        console.error(e)
      }
    },

    clear () {
      this.form = {
        user: null,
        headline: null,
        description: null
      }
      this.image = null
      this.extraImages = []
      this.$v.$reset()
    }
  },

  validations () {
    const rules = {
      form: {
        user: { required }
      }
    }

    if (this.postId) {
      rules.form.offerId = { required }
    }

    if (!this.postId || this.form.offerId === 'NEW') {
      rules.form.headline = { required }
      rules.form.description = { required }
      rules.form.classification = { required }
    }

    return rules
  }
}
</script>

<style lang="scss" scoped>
.extra-images {
  width: 100%;
  display: flex;
  align-items: end;
  .remove {
    display: inline-block;
    margin: 5px 0 5px 10px;
    height: 40px;
    line-height: 40px;
    font-size: 20px;
    cursor: pointer;
  }
  .upload {
    width: 100%;
  }
}

.image {
  max-width: 100%;
  height: 100px;
  object-fit: contain;
}
</style>
