var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gn-drawer",
    {
      attrs: {
        title: "Rejecting reason",
        "before-close": _vm.beforeClose,
        size: 500
      },
      on: {
        closed: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "danger", icon: "el-icon-close" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reject", _vm.rejectingReason)
                    }
                  }
                },
                [_vm._v("\n      Reject\n    ")]
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _c("gn-textarea", {
            attrs: {
              validator: _vm.$v.rejectingReason,
              label: "Rejecting reason",
              placeholder: "Enter Rejecting reason"
            },
            model: {
              value: _vm.rejectingReason,
              callback: function($$v) {
                _vm.rejectingReason = $$v
              },
              expression: "rejectingReason"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }