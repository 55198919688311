var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      staticClass: "w-full",
      attrs: { label: _vm.label, validator: _vm.validator }
    },
    [
      _c("div", { staticClass: "el-input" }, [
        _c("div", { staticClass: "el-input__inner" }, [
          _c("input", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.file,
                expression: "!file"
              }
            ],
            ref: "upload",
            attrs: { type: "file", disabled: _vm.disabled },
            on: {
              blur: function($event) {
                return _vm.$emit("blur")
              },
              change: function($event) {
                return _vm.uploadFile($event)
              }
            }
          }),
          _vm._v(" "),
          _vm.file
            ? _c("div", { staticClass: "selected-file" }, [
                _c("span", { staticClass: "text" }, [
                  _vm._v(_vm._s(_vm.file.name))
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "icons" }, [
                  _c("i", {
                    staticClass: "el-icon-upload2",
                    on: {
                      click: function($event) {
                        return _vm.reupload()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("i", {
                    staticClass: "el-icon-delete",
                    on: {
                      click: function($event) {
                        return _vm.clear()
                      }
                    }
                  })
                ])
              ])
            : _vm._e()
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }