<template>
  <div>
    <portal to="actions">
      <el-button
        type="primary"
        icon="el-icon-plus"
        @click="manage = true">
        Add new Administrator
      </el-button>
    </portal>

    <panel title="Administrators">
      <el-table
        :data="administrators">
        <el-table-column
          label="Admin ID"
          width="100">
          <template slot-scope="scope">
            <a class="link" @click="openEdit(scope.row)">{{ scope.row.id }}</a>
          </template>
        </el-table-column>

        <el-table-column label="First Name">
          <template slot-scope="scope">
            {{ scope.row.firstName }}
          </template>
        </el-table-column>

        <el-table-column label="Last Name">
          <template slot-scope="scope">
            {{ scope.row.lastName }}
          </template>
        </el-table-column>

        <el-table-column
          label="Role"
          width="200">
          <el-tag
            type="success"
            effect="dark"
            size="mini">
            Administrator
          </el-tag>
        </el-table-column>

        <el-table-column
          label="Actions"
          width="200"
          align="right">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="default"
              icon="el-icon-edit"
              @click="openEdit(scope.row)">
              Edit
            </el-button>

            <el-button
              size="mini"
              :type="isBlocked(scope.row) ? 'info' : 'danger'"
              :icon="isBlocked(scope.row) ? 'el-icon-circle-check' : 'el-icon-remove-outline'"
              @click="toggleBlock(scope.row)">
              {{ isBlocked(scope.row) ? 'Unblock' : 'Block' }}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </panel>

    <manage-administrator
      v-if="manage"
      :edit="edit"
      @close="closeManage()" />
  </div>
</template>

<script>
import store from '../../store'

import ManageAdministrator from './ManageAdministrator'

export default {
  async beforeRouteEnter (to, from, next) {
    await store.dispatch('users/load')
    next()
  },

  components: {
    ManageAdministrator
  },

  data () {
    return {
      manage: false,
      edit: null
    }
  },

  computed: {
    administrators () {
      return this.$store.getters['users/list'].filter(user => user.admin)
    }
  },

  created () {
    this.$store.commit('page/setPageInfo', {
      title: 'Administrators',
      breadcrumbs: [
        {
          text: 'Settings'
        },
        {
          text: 'Administrators'
        }
      ]
    })
  },

  methods: {
    async toggleBlock (user) {
      try {
        await this.$confirm(`Are you sure you want to ${user.blocked ? 'unblock' : 'block'} ${user.firstName || ''} ${user.lastName || ''}?`)
        const loading = this.$loading()
        try {
          await this.$store.dispatch('users/update', {
            id: user.id,
            payload: {
              blocked: !user.blocked
            }
          })
        } catch (error) {
          // handler
        } finally {
          loading.close()
        }
      } catch (e) {
        console.error(e)
      }
    },

    openEdit (user) {
      this.edit = user
      this.manage = true
    },

    closeManage () {
      this.edit = null
      this.manage = false
    },

    isBlocked (user) {
      return !!user.blocked
    }
  }
}
</script>

<style lang="scss" scoped>
.link {
  cursor: pointer;
}
</style>