var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          (_vm.verificationZero || _vm.customer.disapprovalReason) &&
          _vm.viewingMyProfile
            ? _c(
                "router-link",
                {
                  staticClass: "mr-5",
                  attrs: {
                    to: {
                      name: "VerifyCustomerProfile",
                      params: { id: _vm.customerId }
                    }
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: _vm.customer.disapprovalReason
                          ? "danger"
                          : "success",
                        size: "medium",
                        icon: _vm.customer.disapprovalReason
                          ? "el-icon-edit"
                          : "el-icon-check"
                      }
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.customer.disapprovalReason
                              ? "Edit & Resubmit Verification"
                              : "Verify Profile"
                          ) +
                          "\n      "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.canSee && _vm.customer.disapprovalReasons.length
            ? _c(
                "el-button",
                {
                  attrs: { size: "medium", icon: "el-icon-time" },
                  on: {
                    click: function($event) {
                      _vm.showDisapprovalReasons = true
                    }
                  }
                },
                [_vm._v("\n      Verification History\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.detailedView
            ? _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "CustomerViewFull",
                      params: { id: _vm.customerId }
                    }
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "default",
                        size: "medium",
                        icon: "el-icon-tickets"
                      }
                    },
                    [_vm._v("\n        Detailed Profile View\n      ")]
                  )
                ],
                1
              )
            : _c(
                "router-link",
                {
                  attrs: {
                    to: { name: "CustomerView", params: { id: _vm.customerId } }
                  }
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "default",
                        size: "medium",
                        icon: "el-icon-back"
                      }
                    },
                    [_vm._v("\n        Profile Overview\n      ")]
                  )
                ],
                1
              )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _c("profile-info", { attrs: { profile: _vm.customer } }),
              _vm._v(" "),
              _c("panel", { attrs: { title: "Profile Info" } }, [
                _c("ul", { staticClass: "gn-list" }, [
                  _c("li", [
                    _c("label", [_vm._v("Profile ID:")]),
                    _vm._v(" "),
                    _c("span", [_vm._v(_vm._s(_vm.customer.uuid))])
                  ]),
                  _vm._v(" "),
                  _c("li", [
                    _c("label", [_vm._v("Created At:")]),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(_vm._s(_vm._f("date")(_vm.customer.createdAt)))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "li",
                    [
                      _c("label", [_vm._v("Location:")]),
                      _vm._v(" "),
                      _vm.customer.geoData &&
                      _vm.customer.geoData.city &&
                      _vm.customer.geoData.country_name
                        ? [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.customer.geoData.city) +
                                  ", " +
                                  _vm._s(_vm.customer.geoData.country_name)
                              )
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "map-wrapper" }, [
                              _c("iframe", {
                                staticStyle: { border: "0" },
                                attrs: {
                                  width: "100%",
                                  height: "230",
                                  loading: "lazy",
                                  src:
                                    "https://www.google.com/maps/embed/v1/place?key=AIzaSyAbdCULaeVllzbd5eC185MKofyUkyqDPR8&q=" +
                                    _vm.customer.geoData.city +
                                    "," +
                                    _vm.customer.geoData.country_name
                                }
                              })
                            ])
                          ]
                        : _c("span", [_vm._v("N/A")])
                    ],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              _vm.detailedView && _vm.documents.length && _vm.canSee
                ? _c("documents", { attrs: { documents: _vm.documents } })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "panel",
                {
                  attrs: { title: "Profile Points" },
                  scopedSlots: _vm._u([
                    {
                      key: "actions",
                      fn: function() {
                        return [
                          _c(
                            "el-tooltip",
                            {
                              attrs: {
                                effect: "dark",
                                content: "Add Points",
                                placement: "top"
                              }
                            },
                            [
                              _c("el-button", {
                                attrs: {
                                  type: "primary",
                                  size: "mini",
                                  icon: "el-icon-plus"
                                },
                                on: {
                                  click: function($event) {
                                    _vm.addProfilePoints = true
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.points.length
                            ? _c(
                                "el-tag",
                                {
                                  attrs: {
                                    size: "medium",
                                    type:
                                      _vm.pointsTotal >= 0
                                        ? "success"
                                        : "danger",
                                    effect: "dark"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.pointsTotal) +
                                      "\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(" "),
                  _vm.points.length
                    ? [
                        _c(
                          "ul",
                          { staticClass: "gn-list-2" },
                          _vm._l(_vm.points, function(points) {
                            return _c(
                              "li",
                              { key: points.id },
                              [
                                _c("div", { staticClass: "item" }, [
                                  _c("label", [_vm._v(_vm._s(points.text))]),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(_vm._f("date")(points.createdAt))
                                    )
                                  ])
                                ]),
                                _vm._v(" "),
                                _c(
                                  "el-tag",
                                  {
                                    attrs: {
                                      size: "small",
                                      type:
                                        points.points >= 0
                                          ? "success"
                                          : "danger"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(points.points >= 0 ? "+" : "") +
                                        _vm._s(points.points)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "items-center" },
                          [
                            _c("el-pagination", {
                              staticClass: "mt-20",
                              attrs: {
                                layout: "prev, pager, next",
                                total: _vm.points.length,
                                "hide-on-single-page": "",
                                background: ""
                              },
                              on: {
                                "current-change": function($event) {
                                  _vm.page = $event
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _c("el-empty", {
                        attrs: {
                          "image-size": 50,
                          description:
                            "This customer has not scored any points yet."
                        }
                      })
                ],
                2
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _vm.verificationZero
                ? _c("el-alert", {
                    staticClass: "mb-20",
                    attrs: {
                      title: "Profile not verified.",
                      description: _vm.viewingMyProfile
                        ? "Your profile is not verifed yet. To verify it click on button Verify Profile in the top right corner."
                        : "This customer profile is not verifed yet.",
                      type: "warning",
                      closable: false
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.canSee && _vm.customer.disapprovalReason
                ? _c("el-alert", {
                    staticClass: "mb-20",
                    attrs: {
                      title: "Disapproval reason:",
                      description: _vm.customer.disapprovalReason.text,
                      type: "error",
                      closable: false
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.customer.inVerification && !_vm.customer.disapprovalReason
                ? _c("panel", [
                    _c(
                      "div",
                      { staticClass: "verification-panel" },
                      [
                        _c("span", [
                          _c("b", [_vm._v("Profile in verification.")]),
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.profileInVerificationText) +
                              "\n          "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "CustomerProfileVerification",
                                params: { id: _vm.customerId }
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "primary",
                                  size: "small",
                                  icon: "el-icon-check"
                                }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.viewingMyProfile
                                        ? "View Submitted Info"
                                        : "Review Profile"
                                    ) +
                                    "\n            "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.canSee && _vm.detailedView
                ? _c("profile-detailed-info", {
                    attrs: { title: "Profile Details", customer: _vm.customer }
                  })
                : _vm._e(),
              _vm._v(" "),
              !_vm.detailedView
                ? [
                    _c(
                      "panel",
                      { attrs: { title: "Posts" } },
                      [
                        _vm.loading.posts
                          ? _c("el-skeleton")
                          : _vm.posts.length
                          ? _c("posts-table", {
                              attrs: { posts: _vm.posts, "hide-profile-id": "" }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "panel",
                      { attrs: { title: "Offers" } },
                      [
                        _vm.loading.offers
                          ? _c("el-skeleton")
                          : _vm.offers.length
                          ? _c("offers-table", {
                              attrs: {
                                offers: _vm.offers,
                                "hide-profile-id": ""
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("panel", { attrs: { title: "Trades" } })
                  ]
                : _vm._e()
            ],
            2
          )
        ],
        1
      ),
      _vm._v(" "),
      _vm.addProfilePoints
        ? _c("manage-profile-points", {
            attrs: { "user-id": _vm.customerId },
            on: {
              close: function($event) {
                _vm.addProfilePoints = false
              },
              add: function($event) {
                return _vm.onProfilePointsAdded($event)
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "Disapproval Reasons",
            visible: _vm.showDisapprovalReasons,
            width: "40%"
          },
          on: {
            "update:visible": function($event) {
              _vm.showDisapprovalReasons = $event
            }
          }
        },
        [
          _c(
            "ul",
            { staticClass: "gn-list" },
            _vm._l(_vm.customer.disapprovalReasons, function(reason) {
              return _c("li", { key: reason.id }, [
                _c("label", [
                  _vm._v(
                    "Disapproval Reason from " +
                      _vm._s(_vm._f("date")(reason.createdAt))
                  )
                ]),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(reason.text))])
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.showDisapprovalReasons = false
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }