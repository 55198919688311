<template>
  <div>
    <panel title="Profile Settings">
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-select
            v-model="form.reg_email"
            :validator="$v.form.reg_email"
            :options="yesNoOptions"
            :show-as-text="!edit"
            label="Send Registration Email" />
        </el-col>
        <el-col :span="6">
          <gn-select
            v-model="form.welcome_email"
            :validator="$v.form.welcome_email"
            :options="yesNoOptions"
            :show-as-text="!edit"
            label="Send Verification Email" />
        </el-col>
      </el-row>
    </panel>

    <panel title="Posts Settings">
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-select
            v-model="form.post_auto_push"
            :validator="$v.form.post_auto_push"
            :options="autoPublishOptions"
            :show-as-text="!edit"
            label="Post Auto-Publish" />
        </el-col>
      </el-row>
    </panel>

    <panel title="Offers Settings">
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-select
            v-model="form.offer_auto_push"
            :validator="$v.form.offer_auto_push"
            :options="autoPublishOptions"
            :show-as-text="!edit"
            label="Offer Auto-Publish" />
        </el-col>
      </el-row>
    </panel>

    <panel title="Trades Settings"> 
      <el-row :gutter="20">
        <el-col :span="6">
          <gn-select
            v-model="form.trade_auto_push"
            :validator="$v.form.trade_auto_push"
            :options="autoPublishOptions.filter(option => option.id !== 'VERIFIED_ONLY')"
            :show-as-text="!edit"
            label="Trade Auto-Publish" />
        </el-col>
      </el-row>
    </panel>

    <div class="items-center">
      <el-button
        v-if="!edit"
        class="save"
        type="primary"
        icon="el-icon-edit"
        :loading="loading"
        @click="edit = true">
        Update
      </el-button>

      <template v-else>
        <el-button
          class="save"
          type="white"
          icon="el-icon-close"
          @click="cancel()">
          Cancel
        </el-button>
        <el-button
          class="save"
          type="primary"
          icon="el-icon-edit"
          :loading="loading"
          @click="save()">
          Save
        </el-button>
      </template>
    </div>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  data () {
    return {
      form: {
        reg_email: null,
        post_auto_push: null,
        offer_auto_push: null,
        trade_auto_push: null,
        welcome_email: null,
      },

      yesNoOptions: [{
        id: true,
        text: 'Yes'
      },
      {
        id: false,
        text: 'No'
      }],

      autoPublishOptions: [{
        id: 'YES',
        text: 'Yes'
      },
      {
        id: 'NO',
        text: 'No'
      },
      {
        id: 'VERIFIED_ONLY',
        text: 'Verified Profiles Only'
      }],

      edit: false,
      loading: false
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  created () {
    this.setPageInfo()
    this.initData()
  },

  methods: {
    initData () {
      this.form = JSON.parse(JSON.stringify(this.system))
      this.$nextTick(() => (this.form.state = this.system.state))
    },

    cancel () {
      this.edit = false
      this.initData()
    },

    async save () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return

      this.loading = true
      try {
        await this.$store.dispatch('system/update', { id: 1, payload: this.form })

        this.$message.success('System Configuration updated successfully.')
        this.edit = false
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },

    setPageInfo () {
      this.$store.commit('page/setPageInfo', {
        title: 'System Configuration',
        breadcrumbs: [
          {
            text: 'Settings'
          },
          {
            text: 'System Configuration'
          }
        ]
      })
    }
  },

  validations: {
    form: {
      reg_email: { required },
      welcome_email: { required },
      post_auto_push: { required },
      offer_auto_push: { required },
      trade_auto_push: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.save {
  width: 100%;
  max-width: 200px;
  margin-top: 20px;
}
</style>