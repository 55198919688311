import { render, staticRenderFns } from "./PostsFilters.vue?vue&type=template&id=072ae9da&"
import script from "./PostsFilters.vue?vue&type=script&lang=js&"
export * from "./PostsFilters.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6giveandget/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('072ae9da')) {
      api.createRecord('072ae9da', component.options)
    } else {
      api.reload('072ae9da', component.options)
    }
    module.hot.accept("./PostsFilters.vue?vue&type=template&id=072ae9da&", function () {
      api.rerender('072ae9da', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/listings/posts/PostsFilters.vue"
export default component.exports