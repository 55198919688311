var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel" }, [
    _vm.title
      ? _c("div", { staticClass: "header" }, [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c("div", { staticClass: "actions" }, [_vm._t("actions")], 2)
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots.default
      ? _c("div", { staticClass: "content" }, [_vm._t("default")], 2)
      : _vm._e(),
    _vm._v(" "),
    _vm.$slots.footer
      ? _c("div", { staticClass: "footer" }, [_vm._t("footer")], 2)
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }