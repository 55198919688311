var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.offers } },
    [
      _c("el-table-column", {
        attrs: { label: "Offer ID", width: "80" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "OfferView", params: { id: scope.row.id } }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Offer - " + _vm._s(scope.row.id) + "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !_vm.hideProfileId
        ? _c("el-table-column", {
            attrs: { label: "Profile ID", width: "80" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "CustomerView",
                              params: { id: scope.row.user.id }
                            },
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(scope.row.user.uuid) +
                              "\n      "
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              705086848
            )
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Pinned to", width: "80" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.postOffer
                        ? _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "PostView",
                                  params: { id: scope.row.postOffer.postId }
                                },
                                target: "_blank"
                              }
                            },
                            [
                              _vm._v(
                                "\n        Post - " +
                                  _vm._s(scope.row.postOffer.postId) +
                                  "\n      "
                              )
                            ]
                          )
                        : [_vm._v("\n        N/A\n      ")]
                    ]
                  }
                }
              ],
              null,
              false,
              2430546915
            )
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Created At", width: "165" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(_vm._f("date")(scope.row.createdAt)) +
                          "\n    "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              155631749
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Featured Image", width: "125" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.image
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: "/files/offers/" + scope.row.image,
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "featured-image",
                            attrs: {
                              src: "/files/offers/" + scope.row.image,
                              fit: "contain"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot with-icon",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline"
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("el-image", { staticClass: "featured-image" }, [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [_vm._v("No image")]
                      )
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Distance" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm._f("distance")(_vm.getDistanceFromMe(scope.row))
                    ) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Headline" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "OfferView", params: { id: scope.row.id } }
                    }
                  },
                  [_c("b", [_vm._v(_vm._s(scope.row.headline))])]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !_vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Status", width: "100" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.postOffer
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                type:
                                  _vm.postOfferStatusColors[
                                    scope.row.postOffer.status
                                  ],
                                effect: "dark",
                                size: "mini"
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm._f("postOfferStatus")(
                                      scope.row.postOffer.status
                                    )
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                        : _c(
                            "el-tag",
                            {
                              attrs: {
                                type: _vm.statusColors[scope.row.status],
                                effect: "dark",
                                size: "mini"
                              }
                            },
                            [
                              _vm._v(
                                "\n        " +
                                  _vm._s(
                                    _vm._f("offerStatus")(scope.row.status)
                                  ) +
                                  "\n      "
                              )
                            ]
                          )
                    ]
                  }
                }
              ],
              null,
              false,
              972972356
            )
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Auto-Published", width: "120" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _vm._v(
                        "\n      " +
                          _vm._s(scope.row.autoPublished ? "Yes" : "No") +
                          "\n    "
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3996445889
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Offer Classification", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("classification")(scope.row.classification)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !_vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Reviewed At", width: "165", align: "right" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.reviewedAt
                        ? [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm._f("date")(scope.row.reviewedAt)) +
                                "\n      "
                            )
                          ]
                        : [_vm._v("\n        N/A\n      ")]
                    ]
                  }
                }
              ],
              null,
              false,
              2884161308
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.forPosts
        ? _c("el-table-column", {
            attrs: { label: "Offer Made At", width: "165", align: "right" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      scope.row.updatedAt
                        ? [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm._f("date")(scope.row.updatedAt)) +
                                "\n      "
                            )
                          ]
                        : [_vm._v("\n        N/A\n      ")]
                    ]
                  }
                }
              ],
              null,
              false,
              3408538556
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }