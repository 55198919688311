var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message", class: { unread: !_vm.message.read } },
    [
      _c("div", { staticClass: "wrapper" }, [
        _c("div", [
          _c("span", { staticClass: "date" }, [
            _vm._v(_vm._s(_vm._f("date")(_vm.message.createdAt)))
          ]),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "text", class: { bold: !_vm.message.read } },
            [
              !_vm.message.read
                ? _c("el-tag", { attrs: { size: "mini" } }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.message.updatedAt ? "UPDATED" : "NEW") +
                        "\n        "
                    )
                  ])
                : _vm._e(),
              _vm._v("\n        " + _vm._s(_vm.message.message) + "\n      ")
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "buttons" },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  effect: "dark",
                  content: "Mark as read",
                  placement: "top"
                }
              },
              [
                !_vm.message.read
                  ? _c("el-button", {
                      attrs: {
                        type: "success",
                        size: "mini",
                        icon: "el-icon-check",
                        plain: "",
                        circle: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.read()
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-tooltip",
              {
                attrs: { effect: "dark", content: "Discard", placement: "top" }
              },
              [
                !_vm.message.discarded
                  ? _c("el-button", {
                      attrs: {
                        type: "danger",
                        size: "mini",
                        icon: "el-icon-close",
                        plain: "",
                        circle: ""
                      },
                      on: {
                        click: function($event) {
                          return _vm.discard()
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }