var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "panel",
    {
      attrs: { title: _vm.title },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function() {
            return [
              _vm.customer.inVerification &&
              _vm.user.id === _vm.customer.id &&
              _vm.customer.disapprovalReason
                ? _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "VerifyCustomerProfile",
                          params: { id: _vm.customer.id }
                        }
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            size: "small",
                            icon: "el-icon-edit"
                          }
                        },
                        [_vm._v("\n        Edit Verification\n      ")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "gn-list", attrs: { gutter: 20 } },
        _vm._l(_vm.data, function(item, index) {
          return _c("el-col", { key: index, attrs: { sm: 12, md: 8, lg: 6 } }, [
            _c("li", [
              _c("label", [_vm._v(_vm._s(item.label) + ":")]),
              _vm._v(" "),
              _c("span", [_vm._v(_vm._s(item.text || "N/A"))])
            ])
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }