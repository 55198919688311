var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    {
      attrs: {
        title: _vm.system.register_header,
        subtitle: _vm.system.register_text,
        right: ""
      }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-position": "top" } },
        [
          _vm.error
            ? _c("div", { staticClass: "textual-error" }, [
                _vm._v("\n      " + _vm._s(_vm.error) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.firstName,
                      label: "First Name",
                      placeholder: "Enter first name"
                    },
                    model: {
                      value: _vm.form.firstName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "firstName", $$v)
                      },
                      expression: "form.firstName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.lastName,
                      label: "Last Name",
                      placeholder: "Enter last name"
                    },
                    model: {
                      value: _vm.form.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lastName", $$v)
                      },
                      expression: "form.lastName"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.email,
              label: "Email",
              placeholder: "Enter email"
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.password,
              options: { "show-password": true },
              label: "Password",
              placeholder: "Enter password"
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.repeatPassword,
              options: { "show-password": true },
              label: "Repeat Password",
              placeholder: "Repeat password"
            },
            model: {
              value: _vm.form.repeatPassword,
              callback: function($$v) {
                _vm.$set(_vm.form, "repeatPassword", $$v)
              },
              expression: "form.repeatPassword"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "agreement mt-20" }, [
            _c("span", [_vm._v("Do you agree to our:")]),
            _vm._v(" "),
            _c("div", { staticClass: "checkboxes" }, [
              _c(
                "span",
                [
                  _c(
                    "gn-checkbox",
                    {
                      attrs: { validator: _vm.$v.form.terms },
                      model: {
                        value: _vm.form.terms,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "terms", $$v)
                        },
                        expression: "form.terms"
                      }
                    },
                    [_vm._v("\n            Terms & Conditions\n          ")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "span",
                [
                  _c(
                    "gn-checkbox",
                    {
                      attrs: { validator: _vm.$v.form.privacy },
                      model: {
                        value: _vm.form.privacy,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "privacy", $$v)
                        },
                        expression: "form.privacy"
                      }
                    },
                    [_vm._v("\n            Privacy Policy\n          ")]
                  )
                ],
                1
              )
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-5" }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "register",
              attrs: { type: "primary", loading: _vm.loading, round: "" },
              on: {
                click: function($event) {
                  return _vm.register()
                }
              }
            },
            [_vm._v("\n      Register\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "already-have-account" },
            [
              _vm._v("\n      Already have account?\n      "),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: { name: "Login" } } },
                [_vm._v("\n        Go to Login page\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }