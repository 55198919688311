<template>
  <form-item
    :label="label"
    :validator="validator">
    <el-select
      v-if="!showAsText"
      v-model="val"
      :disabled="disabled"
      :placeholder="placeholder"
      :clearable="clearable"
      :filterable="filterable"
      class="w-full">
      <el-option
        v-for="option in options"
        :key="option.id"
        :disabled="option.disabled"
        :label="option.text"
        :value="option.id">
      </el-option>
    </el-select>
    <span
      v-else
      class="textual">
      {{ textualValue }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    options: {
      type: Array,
      default: () => {
        return []
      }
    },

    filterable: {
      type: Boolean,
      default: false
    },

    clearable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    textualValue () {
      const item = this.options.find(option => option.id === this.val)
      return item ? item.text : 'N/A'
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>