var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messages" },
    [
      _c(
        "panel",
        {
          attrs: { title: "System Messages" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  !_vm.onlyDiscarded
                    ? [
                        _vm.messages.some(function(message) {
                          return !message.read
                        })
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Mark all as read",
                                  effect: "dark",
                                  placement: "top"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "success",
                                    icon: "el-icon-check",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.readAll()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.messages.some(function(message) {
                          return !message.discarded
                        })
                          ? _c(
                              "el-tooltip",
                              {
                                attrs: {
                                  content: "Discard all",
                                  effect: "dark",
                                  placement: "top"
                                }
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    type: "danger",
                                    icon: "el-icon-close",
                                    size: "small",
                                    plain: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.discardAll()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "el-button-group",
                    { staticClass: "ml-20" },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.onlyDiscarded ? "default" : "primary",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              _vm.onlyDiscarded = false
                            }
                          }
                        },
                        [_vm._v("\n          Inbox\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: _vm.onlyDiscarded ? "primary" : "default",
                            size: "small"
                          },
                          on: {
                            click: function($event) {
                              _vm.onlyDiscarded = true
                            }
                          }
                        },
                        [_vm._v("\n          Discarded\n        ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _vm._l(_vm.messages, function(message) {
            return _c("system-message", {
              key: message.id,
              attrs: { message: message }
            })
          }),
          _vm._v(" "),
          !_vm.messages.length
            ? _c("el-empty", {
                attrs: { description: "There are no system messages found." }
              })
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }