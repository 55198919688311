var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gn-drawer",
    {
      attrs: { value: true, title: "Add Profile Points" },
      on: {
        closed: function() {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [_vm._v("\n      Save\n    ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          !_vm.userId
            ? _c("gn-select", {
                attrs: {
                  options: _vm.profileOptions,
                  validator: _vm.$v.form.userId,
                  label: "Profile",
                  placeholder: "Select Profile",
                  filterable: ""
                },
                model: {
                  value: _vm.form.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "userId", $$v)
                  },
                  expression: "form.userId"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.text,
              maxlength: "50",
              label: "Text",
              placeholder: "Enter Text"
            },
            model: {
              value: _vm.form.text,
              callback: function($$v) {
                _vm.$set(_vm.form, "text", $$v)
              },
              expression: "form.text"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.points,
              label: "Points",
              placeholder: "Enter Points"
            },
            model: {
              value: _vm.form.points,
              callback: function($$v) {
                _vm.$set(_vm.form, "points", _vm._n($$v))
              },
              expression: "form.points"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }