var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    {
      attrs: { title: _vm.system.login_header, subtitle: _vm.system.login_text }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-position": "top" } },
        [
          _vm.error
            ? _c("div", { staticClass: "textual-error" }, [
                _vm._v("\n      " + _vm._s(_vm.error) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.email,
              label: "Email",
              placeholder: "Enter email"
            },
            on: {
              enter: function($event) {
                return _vm.login()
              }
            },
            model: {
              value: _vm.form.email,
              callback: function($$v) {
                _vm.$set(_vm.form, "email", $$v)
              },
              expression: "form.email"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.password,
              options: { "show-password": true },
              label: "Password",
              placeholder: "Enter password"
            },
            on: {
              enter: function($event) {
                return _vm.login()
              }
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _vm._v(" "),
          false
            ? _c(
                "router-link",
                {
                  staticClass: "link forgot-password",
                  attrs: { to: { name: "Register" } }
                },
                [_vm._v("\n      Forgot password?\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "login",
              attrs: { type: "primary", loading: _vm.loading, round: "" },
              on: {
                click: function($event) {
                  return _vm.login()
                }
              }
            },
            [_vm._v("\n      Login\n    ")]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "create-an-account" },
            [
              _vm._v("\n      Not registered yet?\n      "),
              _c(
                "router-link",
                { staticClass: "link", attrs: { to: { name: "Register" } } },
                [_vm._v("\n        Create an Account\n      ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }