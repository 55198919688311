var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("panel", { attrs: { title: _vm.title } }, [
    _c(
      "ul",
      { staticClass: "documents" },
      _vm._l(_vm.documents, function(document) {
        return _c("li", { key: document.id }, [
          _c(
            "span",
            [
              _c("a", { attrs: { href: document.url, target: "_blank" } }, [
                _vm._v("\n          " + _vm._s(document.name) + "\n        ")
              ]),
              _vm._v(" "),
              document.info
                ? [
                    _vm._v(
                      "\n          (" + _vm._s(document.info) + ")\n        "
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "a",
            { attrs: { href: document.url, target: "_blank" } },
            [
              _c(
                "el-button",
                { attrs: { type: "default", size: "mini", round: "" } },
                [_vm._v("\n          View\n        ")]
              )
            ],
            1
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }