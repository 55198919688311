var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "panel",
        {
          attrs: { title: "Points Configuration" },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        size: "medium",
                        icon: "el-icon-plus"
                      },
                      on: {
                        click: function($event) {
                          _vm.addProfilePoints = true
                        }
                      }
                    },
                    [_vm._v("\n        Manually add points\n      ")]
                  )
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm._v(" "),
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "Profile Activation"
                    },
                    model: {
                      value: _vm.form.activationPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "activationPoints", _vm._n($$v))
                      },
                      expression: "form.activationPoints"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "Profile Verification"
                    },
                    model: {
                      value: _vm.form.verificationPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "verificationPoints", _vm._n($$v))
                      },
                      expression: "form.verificationPoints"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "New Item Post"
                    },
                    model: {
                      value: _vm.form.newItemPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newItemPoints", _vm._n($$v))
                      },
                      expression: "form.newItemPoints"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "New Favor Post"
                    },
                    model: {
                      value: _vm.form.newFavorPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newFavorPoints", _vm._n($$v))
                      },
                      expression: "form.newFavorPoints"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "New Item Offer"
                    },
                    model: {
                      value: _vm.form.newItemOfferPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newItemOfferPoints", _vm._n($$v))
                      },
                      expression: "form.newItemOfferPoints"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      "show-as-text": !_vm.edit,
                      label: "New Favor Offer"
                    },
                    model: {
                      value: _vm.form.newFavorOfferPoints,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "newFavorOfferPoints", _vm._n($$v))
                      },
                      expression: "form.newFavorOfferPoints"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("panel", [
        _c(
          "div",
          { staticClass: "items-center" },
          [
            !_vm.edit
              ? _c(
                  "el-button",
                  {
                    staticClass: "save",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-edit",
                      loading: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        _vm.edit = true
                      }
                    }
                  },
                  [_vm._v("\n        Update\n      ")]
                )
              : [
                  _c(
                    "el-button",
                    {
                      staticClass: "save",
                      attrs: { type: "white", icon: "el-icon-close" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "save",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-edit",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("\n          Save\n        ")]
                  )
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.addProfilePoints
        ? _c("manage-profile-points", {
            on: {
              close: function($event) {
                _vm.addProfilePoints = false
              },
              add: function($event) {
                _vm.addProfilePoints = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }