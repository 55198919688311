<template>
  <nav id="sidebar">
    <ul id="menu">
      <li
        v-for="item in menu"
        :key="item.name">
        <router-link :to="item.to">
          <i :class="item.icon" />
          <span>
            {{ item.name }}
          </span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data () {
    return {
      menu: [
        {
          name: 'Dashboard',
          icon: 'el-icon-data-line',
          to: { name: 'Dashboard' }
        },
        {
          name: 'Customers',
          icon: 'el-icon-user',
          to: { name: 'Customers' }
        },
        {
          name: 'Listings',
          icon: 'el-icon-document-copy',
          to: { name: 'Listings' }
        },
        {
          name: 'Tools',
          icon: 'icon-tools-2',
          to: { name: 'Tools' }
        },
        {
          name: 'Settings',
          icon: 'el-icon-setting',
          to: { name: 'Settings' }
        }
      ]
    }
  }
}
</script>
