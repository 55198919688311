import { render, staticRenderFns } from "./AccountVerification.vue?vue&type=template&id=725dbbfc&scoped=true&"
import script from "./AccountVerification.vue?vue&type=script&lang=js&"
export * from "./AccountVerification.vue?vue&type=script&lang=js&"
import style0 from "./AccountVerification.vue?vue&type=style&index=0&id=725dbbfc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "725dbbfc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6giveandget/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('725dbbfc')) {
      api.createRecord('725dbbfc', component.options)
    } else {
      api.reload('725dbbfc', component.options)
    }
    module.hot.accept("./AccountVerification.vue?vue&type=template&id=725dbbfc&scoped=true&", function () {
      api.rerender('725dbbfc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/auth/AccountVerification.vue"
export default component.exports