<template>
  <main>
    <template v-if="!user || user.status !== 'REGISTERED'">
      <sidebar />
      <div id="page">
        <main-header />
        <page-info />
        <section>
          <router-view />
        </section>
      </div>
    </template>

    <change-password v-else />
  </main>
</template>

<script>
import store from '../store'

import Sidebar from '../components/layout/Sidebar'
import PageInfo from '../components/layout/PageInfo'
import MainHeader from '../components/layout/Header'
import ChangePassword from '../components/auth/ChangePassword'

export default {
  async beforeRouteEnter (to, from, next) {
    const promises = [
      store.dispatch('readed/load', { params: { 'q[userId]': window.appData.currentUser.id } }),
      store.dispatch('discarded/load', { params: { 'q[userId]': window.appData.currentUser.id } }),
      store.dispatch('systemMessage/load')
    ]

    await Promise.all(promises)
    next()
  },

  components: {
    Sidebar,
    PageInfo,
    MainHeader,
    ChangePassword
  },

  computed: {
    user () {
      return this.$store.state.user.user
    }
  }
}
</script>

<style lang="scss" scoped>
#page {
  width: 100%;
  padding: 100px 20px 20px 90px;
  box-sizing: border-box;
}
</style>