var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      staticClass: "w-full",
      attrs: { label: _vm.label, validator: _vm.validator }
    },
    [
      !_vm.showAsText
        ? _c(
            "el-date-picker",
            _vm._b(
              {
                attrs: {
                  type: _vm.type,
                  disabled: _vm.disabled,
                  placeholder: _vm.placeholder || "Select date",
                  "picker-options": _vm.pickerOptions,
                  "range-separator": "To",
                  "value-format": "yyyy-MM-dd",
                  "start-placeholder": "Start date",
                  "end-placeholder": "End date"
                },
                model: {
                  value: _vm.val,
                  callback: function($$v) {
                    _vm.val = $$v
                  },
                  expression: "val"
                }
              },
              "el-date-picker",
              _vm.options,
              false
            )
          )
        : _c("span", { staticClass: "textual" }, [
            _vm._v("\n    " + _vm._s(_vm.val || "N/A") + "\n  ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }