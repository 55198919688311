var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.posts } },
    [
      _c("el-table-column", {
        attrs: { label: "Post #", width: "70" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "PostView", params: { id: scope.row.id } }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Post - " + _vm._s(scope.row.id) + "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      !_vm.hideProfileId
        ? _c("el-table-column", {
            attrs: { label: "Profile ID", width: "80" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "router-link",
                        {
                          attrs: {
                            to: {
                              name: "CustomerView",
                              params: { id: scope.row.user.id }
                            },
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(scope.row.user.uuid) +
                              "\n      "
                          )
                        ]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              705086848
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Created At", width: "165" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("date")(scope.row.createdAt)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Exp. Date", width: "165" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("date")(scope.row.expiringAt)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Featured Image", width: "125" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.image
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: "/files/posts/" + scope.row.image,
                          target: "_blank"
                        }
                      },
                      [
                        _c(
                          "el-image",
                          {
                            staticClass: "featured-image",
                            attrs: {
                              src: "/files/posts/" + scope.row.image,
                              fit: "contain"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "image-slot with-icon",
                                attrs: { slot: "error" },
                                slot: "error"
                              },
                              [
                                _c("i", {
                                  staticClass: "el-icon-picture-outline"
                                })
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  : _c("el-image", { staticClass: "featured-image" }, [
                      _c(
                        "div",
                        {
                          staticClass: "image-slot",
                          attrs: { slot: "error" },
                          slot: "error"
                        },
                        [_vm._v("No image")]
                      )
                    ])
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Headline" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "PostView", params: { id: scope.row.id } }
                    }
                  },
                  [_c("b", [_vm._v(_vm._s(scope.row.headline))])]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Status", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.statusColors[scope.row.status],
                      effect: "dark",
                      size: "mini"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("postStatus")(scope.row.status)) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Auto-Published", width: "120" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(scope.row.autoPublished ? "Yes" : "No") +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Post Classification", width: "140" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("classification")(scope.row.classification)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Reviewed At", width: "165" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                scope.row.reviewedAt
                  ? [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm._f("date")(scope.row.reviewedAt)) +
                          "\n      "
                      )
                    ]
                  : [_vm._v("\n        N/A\n      ")]
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }