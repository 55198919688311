var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "panel",
        { attrs: { title: "Organization Identification Information" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.name,
                      "show-as-text": !_vm.edit,
                      label: "Rep First Name"
                    },
                    model: {
                      value: _vm.form.name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "name", $$v)
                      },
                      expression: "form.name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.lastName,
                      "show-as-text": !_vm.edit,
                      label: "Rep Last Name"
                    },
                    model: {
                      value: _vm.form.lastName,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "lastName", $$v)
                      },
                      expression: "form.lastName"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.company_name,
                      "show-as-text": !_vm.edit,
                      label: "Company Name"
                    },
                    model: {
                      value: _vm.form.company_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "company_name", $$v)
                      },
                      expression: "form.company_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.dba_name,
                      "show-as-text": !_vm.edit,
                      label: "DBA Name"
                    },
                    model: {
                      value: _vm.form.dba_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dba_name", $$v)
                      },
                      expression: "form.dba_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.url,
                      "show-as-text": !_vm.edit,
                      label: "URL"
                    },
                    model: {
                      value: _vm.form.url,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "url", $$v)
                      },
                      expression: "form.url"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Organization Address Information" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.address,
                      "show-as-text": !_vm.edit,
                      label: "Address"
                    },
                    model: {
                      value: _vm.form.address,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "address", $$v)
                      },
                      expression: "form.address"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.city,
                      "show-as-text": !_vm.edit,
                      label: "City"
                    },
                    model: {
                      value: _vm.form.city,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "city", $$v)
                      },
                      expression: "form.city"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.postal,
                      "show-as-text": !_vm.edit,
                      label: "Postal Code/Zip"
                    },
                    model: {
                      value: _vm.form.postal,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "postal", $$v)
                      },
                      expression: "form.postal"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.country,
                      "show-as-text": !_vm.edit,
                      options: _vm.countryOptions,
                      label: "Country",
                      filterable: ""
                    },
                    model: {
                      value: _vm.form.country,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "country", $$v)
                      },
                      expression: "form.country"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.state,
                      "show-as-text": !_vm.edit,
                      disabled: !_vm.countryHasState,
                      options: _vm.stateOptions,
                      label: "Province/State",
                      filterable: ""
                    },
                    model: {
                      value: _vm.form.state,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "state", $$v)
                      },
                      expression: "form.state"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Organization Contact Information" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.phone,
                      "show-as-text": !_vm.edit,
                      label: "Phone"
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.email,
                      "show-as-text": !_vm.edit,
                      label: "Email"
                    },
                    model: {
                      value: _vm.form.email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "email", $$v)
                      },
                      expression: "form.email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.main_website,
                      "show-as-text": !_vm.edit,
                      label: "Website"
                    },
                    model: {
                      value: _vm.form.main_website,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "main_website", $$v)
                      },
                      expression: "form.main_website"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.support_phone,
                      "show-as-text": !_vm.edit,
                      label: "Support Phone"
                    },
                    model: {
                      value: _vm.form.support_phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "support_phone", $$v)
                      },
                      expression: "form.support_phone"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.support_email,
                      "show-as-text": !_vm.edit,
                      label: "Support Email"
                    },
                    model: {
                      value: _vm.form.support_email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "support_email", $$v)
                      },
                      expression: "form.support_email"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Display Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.login_header,
                      "show-as-text": !_vm.edit,
                      label: "Login Header"
                    },
                    model: {
                      value: _vm.form.login_header,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "login_header", $$v)
                      },
                      expression: "form.login_header"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.login_text,
                      "show-as-text": !_vm.edit,
                      label: "Login Text"
                    },
                    model: {
                      value: _vm.form.login_text,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "login_text", $$v)
                      },
                      expression: "form.login_text"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.register_header,
                      "show-as-text": !_vm.edit,
                      label: "Register Header"
                    },
                    model: {
                      value: _vm.form.register_header,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "register_header", $$v)
                      },
                      expression: "form.register_header"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.register_text,
                      "show-as-text": !_vm.edit,
                      label: "Register Text"
                    },
                    model: {
                      value: _vm.form.register_text,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "register_text", $$v)
                      },
                      expression: "form.register_text"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _vm.edit
                    ? _c("gn-upload-input", {
                        attrs: { label: "Logo", "only-images": "" },
                        model: {
                          value: _vm.logo,
                          callback: function($$v) {
                            _vm.logo = $$v
                          },
                          expression: "logo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.edit
                    ? _c("label", { staticClass: "simple-label" }, [
                        _vm._v("\n          Logo:\n        ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.system.logo
                    ? _c(
                        "a",
                        {
                          class: { "simple-label-value": !_vm.edit },
                          attrs: {
                            href: "/files/system/" + _vm.system.logo,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.system.logo) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Mail Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.mail_from_name,
                      "show-as-text": !_vm.edit,
                      label: "Mail - From Name"
                    },
                    model: {
                      value: _vm.form.mail_from_name,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mail_from_name", $$v)
                      },
                      expression: "form.mail_from_name"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-input", {
                    attrs: {
                      validator: _vm.$v.form.mail_from_email,
                      "show-as-text": !_vm.edit,
                      label: "Mail - from Email"
                    },
                    model: {
                      value: _vm.form.mail_from_email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "mail_from_email", $$v)
                      },
                      expression: "form.mail_from_email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _vm.edit
                    ? _c("gn-upload-input", {
                        attrs: { label: "Mail Logo", "only-images": "" },
                        model: {
                          value: _vm.emailLogo,
                          callback: function($$v) {
                            _vm.emailLogo = $$v
                          },
                          expression: "emailLogo"
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.edit
                    ? _c("label", { staticClass: "simple-label" }, [
                        _vm._v("\n          Mail Logo:\n        ")
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.system.email_logo
                    ? _c(
                        "a",
                        {
                          class: { "simple-label-value": !_vm.edit },
                          attrs: {
                            href: "/files/system/" + _vm.system.email_logo,
                            target: "_blank"
                          }
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.system.email_logo) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("panel", [
        _c(
          "div",
          { staticClass: "items-center" },
          [
            !_vm.edit
              ? _c(
                  "el-button",
                  {
                    staticClass: "save",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-edit",
                      loading: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        _vm.edit = true
                      }
                    }
                  },
                  [_vm._v("\n        Update\n      ")]
                )
              : [
                  _c(
                    "el-button",
                    {
                      staticClass: "save",
                      attrs: { type: "white", icon: "el-icon-close" },
                      on: {
                        click: function($event) {
                          return _vm.cancel()
                        }
                      }
                    },
                    [_vm._v("\n          Cancel\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "save",
                      attrs: {
                        type: "primary",
                        icon: "el-icon-edit",
                        loading: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v("\n          Save\n        ")]
                  )
                ]
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }