var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "image-panel",
    {
      attrs: {
        title: "Give and Get",
        subtitle: "Stuff exchange platform.",
        image: "assets/images/teddy_bear.svg",
        right: _vm.right,
        footer: ""
      }
    },
    [
      _c("section", { staticClass: "content-wrapper" }, [
        _c("img", { staticClass: "logo", attrs: { src: _vm.logo } }),
        _vm._v(" "),
        _c("h1", { staticClass: "gn-title" }, [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        _c("h2", { staticClass: "gn-subtitle" }, [
          _vm._v(_vm._s(_vm.subtitle))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "wrapper" }, [_vm._t("default")], 2)
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }