<template>
  <auth
    :title="system.login_header"
    :subtitle="system.login_text">
    <el-form
      :model="form"
      label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <gn-input
        v-model="form.email"
        :validator="$v.form.email"
        label="Email"
        placeholder="Enter email"
        @enter="login()" />

      <gn-input
        v-model="form.password"
        :validator="$v.form.password"
        :options="{ 'show-password': true }"
        label="Password"
        placeholder="Enter password"
        @enter="login()" />

      <router-link
        v-if="false"
        :to="{ name: 'Register' }"
        class="link forgot-password">
        Forgot password?
      </router-link>

      <el-button
        class="login"
        type="primary"
        :loading="loading"
        round
        @click="login()">
        Login
      </el-button>

      
      <div class="create-an-account">
        Not registered yet?
        <router-link
          :to="{ name: 'Register' }"
          class="link">
          Create an Account
        </router-link>
      </div>
    </el-form>
  </auth>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

import Auth from '../../views/Auth'

export default {
  components: {
    Auth
  },

  data () {
    return {
      form: {
        email: null,
        password: null
      },
      loading: false,
      error: null
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  methods: {
    async login () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('user/login', this.form)
        document.location.href = "/dashboard"
      } catch (error) {
        this.error = error.response.data.error
      } finally {
        this.loading = false
      }
    }
  },

  validations: {
    form: {
      email: { required },
      password: { required }
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  font-size: 16px;
  padding: 15px 23px;
  border-radius: 999px;
  margin-top: 20px;
}

.link {
  font-weight: 600;
  font-size: 16px;
}

.forgot-password {
  display: block;
  text-align: right;
  margin: 20px 0;
}

.create-an-account {
  display: block;
  text-align: center;
  margin-top: 20px;
}
</style>
