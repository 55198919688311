var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gn-drawer",
    {
      attrs: {
        title: _vm.postId ? "Make Offer" : "Add new Offer",
        "before-close": _vm.beforeClose,
        size: 500,
        "destroy-on-close": ""
      },
      on: {
        closed: function($event) {
          return _vm.$emit("close")
        }
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "el-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$emit("close")
                    }
                  }
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  staticClass: "button",
                  attrs: { type: "primary", loading: _vm.loading },
                  on: {
                    click: function($event) {
                      return _vm.save()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.loading ? "Saving..." : "Save") +
                      "\n    "
                  )
                ]
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _c(
        "el-form",
        { attrs: { "label-position": "top" } },
        [
          _vm.user.admin
            ? _c("gn-select", {
                attrs: {
                  validator: _vm.$v.form.user,
                  options: _vm.profileOptions,
                  label: "Profile",
                  placeholder: "Select profile",
                  filterable: ""
                },
                model: {
                  value: _vm.form.user,
                  callback: function($$v) {
                    _vm.$set(_vm.form, "user", $$v)
                  },
                  expression: "form.user"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("gn-select", {
            attrs: {
              options: _vm.classificationsOptions,
              validator: _vm.$v.form.classification,
              label: "Offer Classification",
              placeholder: "Select offer classification",
              filterable: ""
            },
            model: {
              value: _vm.form.classification,
              callback: function($$v) {
                _vm.$set(_vm.form, "classification", $$v)
              },
              expression: "form.classification"
            }
          }),
          _vm._v(" "),
          _vm.postId
            ? [
                _c("gn-select", {
                  attrs: {
                    validator: _vm.$v.form.offerId,
                    options: _vm.offerOptions,
                    label: "Offer Options",
                    placeholder: "Select Offer Options",
                    filterable: ""
                  },
                  model: {
                    value: _vm.form.offerId,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "offerId", $$v)
                    },
                    expression: "form.offerId"
                  }
                }),
                _vm._v(" "),
                _vm.form.offerId === "NEW"
                  ? _c(
                      "gn-checkbox",
                      {
                        model: {
                          value: _vm.form.saved,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "saved", $$v)
                          },
                          expression: "form.saved"
                        }
                      },
                      [_vm._v("\n        Save to my offers\n      ")]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.postId || _vm.form.offerId === "NEW"
            ? [
                _c("gn-input", {
                  attrs: {
                    validator: _vm.$v.form.headline,
                    maxlength: "50",
                    label: "Headline",
                    placeholder: "Enter headline"
                  },
                  model: {
                    value: _vm.form.headline,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "headline", $$v)
                    },
                    expression: "form.headline"
                  }
                }),
                _vm._v(" "),
                _c("gn-textarea", {
                  attrs: {
                    validator: _vm.$v.form.description,
                    maxlength: "1000",
                    label: "Description",
                    placeholder: "Enter description"
                  },
                  model: {
                    value: _vm.form.description,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "description", $$v)
                    },
                    expression: "form.description"
                  }
                }),
                _vm._v(" "),
                _c("gn-upload-input", {
                  attrs: { label: "Featured Image", "only-images": "" },
                  model: {
                    value: _vm.image,
                    callback: function($$v) {
                      _vm.image = $$v
                    },
                    expression: "image"
                  }
                }),
                _vm._v(" "),
                _vm._l(_vm.extraImages, function(extraImg, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "extra-images" },
                    [
                      _c("gn-upload-input", {
                        staticClass: "upload",
                        attrs: {
                          label: "Extra Image " + (index + 1),
                          "only-images": ""
                        },
                        model: {
                          value: extraImg.file,
                          callback: function($$v) {
                            _vm.$set(extraImg, "file", $$v)
                          },
                          expression: "extraImg.file"
                        }
                      }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "remove el-icon-close",
                        on: {
                          click: function($event) {
                            return _vm.removeExtraImage(index)
                          }
                        }
                      })
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.extraImages.length < _vm.maxExtraImages
                  ? _c(
                      "el-link",
                      {
                        attrs: { type: "primary" },
                        on: {
                          click: function($event) {
                            return _vm.addExtraImage()
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        Add Extra Image (" +
                            _vm._s(_vm.extraImages.length) +
                            " / " +
                            _vm._s(_vm.maxExtraImages) +
                            ")\n      "
                        )
                      ]
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.form.offerId !== "NEW" && _vm.selectedOffer
            ? [
                _c(
                  "ul",
                  { staticClass: "gn-list mt-20" },
                  [
                    _vm._l(_vm.offerData, function(item, index) {
                      return _c("li", { key: index }, [
                        _c("label", [_vm._v(_vm._s(item.label) + ":")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(item.value))])
                      ])
                    }),
                    _vm._v(" "),
                    _c("li", [
                      _c("label", [_vm._v("Featured Image:")]),
                      _vm._v(" "),
                      _c(
                        "span",
                        [
                          _vm.selectedOffer.image
                            ? [
                                _c("img", {
                                  staticClass: "image",
                                  attrs: {
                                    src:
                                      "/files/offers/" + _vm.selectedOffer.image
                                  }
                                })
                              ]
                            : [_vm._v("N/A")]
                        ],
                        2
                      )
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.selectedExtraImages, function(image, index) {
                      return _c("li", { key: index }, [
                        _c("label", [
                          _vm._v("Extra Image " + _vm._s(index + 1) + ":")
                        ]),
                        _vm._v(" "),
                        _c("span", [
                          _c("img", {
                            staticClass: "image",
                            attrs: { src: image }
                          })
                        ])
                      ])
                    })
                  ],
                  2
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }