<template>
  <div>
    <navigation :menu="menu" />

    <router-view />
  </div>
</template>

<script>
import Navigation from '../components/common/ui/Navigation'

export default {
  components: {
    Navigation
  },

  computed: {
    menu () {
      return [
        {
          name: 'Organization Settings',
          to: { name: 'OrganizationSettings' }
        },
        {
          name: 'Administrators',
          to: { name: 'Administrators' }
        },
        {
          name: 'Change Log',
          to: { name: 'ChangeLog' }
        },
        {
          name: 'System Configuration',
          to: { name: 'SystemConfiguration' }
        },
        {
          name: 'Points Configuration',
          to: { name: 'PointsConfigSettings' }
        }
      ]
    }
  }
}
</script>
