var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-table",
    { attrs: { data: _vm.trades } },
    [
      _c("el-table-column", {
        attrs: { label: "Trade #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: { name: "TradeView", params: { id: scope.row.id } }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Trade -" + _vm._s(scope.row.id) + "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Post #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "PostView",
                        params: { id: scope.row.post.id }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Post - " +
                        _vm._s(scope.row.post.id) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Post Profile", width: "150" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "CustomerView",
                        params: { id: scope.row.post.user.id }
                      },
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.post.user.uuid) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Offer #", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "PostView",
                        params: { id: scope.row.offer.id }
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n        Offer - " +
                        _vm._s(scope.row.offer.id) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Offer Profile", width: "150" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: {
                        name: "CustomerView",
                        params: { id: scope.row.offer.user.id }
                      },
                      target: "_blank"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(scope.row.offer.user.uuid) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Trade Date" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("date")(scope.row.createdAt)) +
                    "\n    "
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Status", width: "100" },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(scope) {
              return [
                _c(
                  "el-tag",
                  {
                    attrs: {
                      type: _vm.statusColors[scope.row.status],
                      effect: "dark",
                      size: "mini"
                    }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("tradeStatus")(scope.row.status)) +
                        "\n      "
                    )
                  ]
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("el-table-column", {
        attrs: { label: "Actions", width: "200", align: "right" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }