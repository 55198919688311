var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { staticClass: "navigation" }, [
    _c(
      "ul",
      _vm._l(_vm.menu, function(item, index) {
        return _c(
          "li",
          { key: index },
          [
            _c("router-link", { attrs: { to: item.to } }, [
              _vm._v("\n        " + _vm._s(item.name) + "\n      ")
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }