var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("nav", { attrs: { id: "sidebar" } }, [
    _c(
      "ul",
      { attrs: { id: "menu" } },
      _vm._l(_vm.menu, function(item) {
        return _c(
          "li",
          { key: item.name },
          [
            _c("router-link", { attrs: { to: item.to } }, [
              _c("i", { class: item.icon }),
              _vm._v(" "),
              _c("span", [
                _vm._v("\n          " + _vm._s(item.name) + "\n        ")
              ])
            ])
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }