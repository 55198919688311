<template>
  <auth
    :title="system.register_header"
    :subtitle="system.register_text"
    right>
    <el-form
      :model="form"
      label-position="top">
      <div
        v-if="error"
        class="textual-error">
        {{ error }}
      </div>

      <el-row :gutter="20">
        <el-col :span="12">
          <gn-input
            v-model="form.firstName"
            :validator="$v.form.firstName"
            label="First Name"
            placeholder="Enter first name" />
        </el-col>
        <el-col :span="12">
          <gn-input
            v-model="form.lastName"
            :validator="$v.form.lastName"
            label="Last Name"
            placeholder="Enter last name" />
        </el-col>
      </el-row>

      <gn-input
        v-model="form.email"
        :validator="$v.form.email"
        label="Email"
        placeholder="Enter email" />

      <gn-input
        v-model="form.password"
        :validator="$v.form.password"
        :options="{ 'show-password': true }"
        label="Password"
        placeholder="Enter password" />

      <gn-input
        v-model="form.repeatPassword"
        :validator="$v.form.repeatPassword"
        :options="{ 'show-password': true }"
        label="Repeat Password"
        placeholder="Repeat password" />

      <div class="agreement mt-20">
        <span>Do you agree to our:</span>

        <div class="checkboxes">
          <span>
            <gn-checkbox
              v-model="form.terms"
              :validator="$v.form.terms">
              Terms & Conditions
            </gn-checkbox>
          </span>

          <span>
            <gn-checkbox
              v-model="form.privacy"
              :validator="$v.form.privacy">
              Privacy Policy
            </gn-checkbox>
          </span>
        </div>
      </div>
      <div class="mt-5">
      </div>

      <el-button
        class="register"
        type="primary"
        :loading="loading"
        round
        @click="register()">
        Register
      </el-button>

      
      <div class="already-have-account">
        Already have account?
        <router-link
          :to="{ name: 'Login' }"
          class="link">
          Go to Login page
        </router-link>
      </div>
    </el-form>
  </auth>
</template>

<script>
import { isTruthy, passwordValidation } from '../common/validations'
import { required, email, minLength, sameAs } from 'vuelidate/lib/validators'

import Auth from '../../views/Auth'

export default {
  components: {
    Auth
  },

  data () {
    return {
      form: {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        repeatPassword: null,
        terms: false,
        privacy: false
      },
      loading: false,
      error: null
    }
  },

  computed: {
    system () {
      return this.$store.getters['system/get'](1)
    }
  },

  methods: {
    async register () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) return
      this.loading = true
      try {
        await this.$store.dispatch('user/register', this.form)
        const message = this.system.reg_email
          ? 'In order to finish registration, please open verification link in email that we\'ve sent you to verify your account. (If you didn\'t get a email, make sure to check a spam folder too.)'
          : 'You can now login to your account'
        try {
          await this.$alert(`You have successfully created account. ${message}`, 'Registration complete', {
            confirmButtonText: 'Go to Login page'
          })
          this.$router.push({ name: 'Login' })
        } catch (e) {
          console.error(e)
        } finally {
          this.clear()
        }
      } catch (error) {
        this.error = error.response.data.error
        this.clear()
      } finally {
        this.loading = false
      }
    },

    clear () {
      this.form = {
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        repeatPassword: null,
        terms: false,
        privacy: false
      }
      this.$v.$reset()
    }
  },

  validations: {
    form: {
      firstName: { required },
      lastName: { required },
      email: { required, email },
      password: { required, passwordValidation, minLength: minLength(6) },
      repeatPassword: { required, sameAs: sameAs('password') },
      terms: { isTruthy },
      privacy: { isTruthy },
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  width: 100%;
  font-size: 16px;
  padding: 15px 23px;
  border-radius: 999px;
  margin-top: 20px;
}

.link {
  font-weight: 600;
  font-size: 16px;
}

.already-have-account {
  display: block;
  text-align: center;
  margin-top: 20px;
}

.agreement {
  text-align: center;
  font-weight: 600;
  .checkboxes {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
