import { render, staticRenderFns } from "./ProfileInfo.vue?vue&type=template&id=511e7dcc&scoped=true&"
import script from "./ProfileInfo.vue?vue&type=script&lang=js&"
export * from "./ProfileInfo.vue?vue&type=script&lang=js&"
import style0 from "./ProfileInfo.vue?vue&type=style&index=0&id=511e7dcc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "511e7dcc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/c6giveandget/assets/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('511e7dcc')) {
      api.createRecord('511e7dcc', component.options)
    } else {
      api.reload('511e7dcc', component.options)
    }
    module.hot.accept("./ProfileInfo.vue?vue&type=template&id=511e7dcc&scoped=true&", function () {
      api.rerender('511e7dcc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "js/components/customers/ProfileInfo.vue"
export default component.exports