var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      staticClass: "w-full",
      attrs: { label: _vm.label, validator: _vm.validator }
    },
    [
      !_vm.showAsText
        ? _c(
            "el-input",
            _vm._b(
              {
                attrs: {
                  type: _vm.type,
                  placeholder: _vm.placeholder,
                  maxlength: _vm.maxlength,
                  "show-word-limit": !!_vm.maxlength,
                  min: _vm.min,
                  max: _vm.max,
                  disabled: _vm.disabled
                },
                on: {
                  blur: function($event) {
                    return _vm.$emit("blur")
                  },
                  change: function($event) {
                    return _vm.$emit("change", $event)
                  }
                },
                nativeOn: {
                  keyup: [
                    function($event) {
                      return _vm.$emit("keyup", $event)
                    },
                    function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.$emit("enter")
                    }
                  ]
                },
                model: {
                  value: _vm.val,
                  callback: function($$v) {
                    _vm.val = $$v
                  },
                  expression: "val"
                }
              },
              "el-input",
              _vm.options,
              false
            )
          )
        : _c("span", { staticClass: "textual" }, [
            _vm._v("\n    " + _vm._s(_vm.val || "N/A") + "\n  ")
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }