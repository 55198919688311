<template>
  <el-table
    :data="posts">
    <el-table-column
      label="Post #"
      width="70">
      <template slot-scope="scope">
        <router-link :to="{ name: 'PostView', params: { id: scope.row.id } }">
          Post - {{ scope.row.id }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      v-if="!hideProfileId"
      label="Profile ID"
      width="80">
      <template slot-scope="scope">
        <router-link
          :to="{ name: 'CustomerView', params: { id: scope.row.user.id } }"
          target="_blank">
          {{ scope.row.user.uuid }}
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Created At"
      width="165">
      <template slot-scope="scope">
        {{ scope.row.createdAt | date }}
      </template>
    </el-table-column>

    <el-table-column
      label="Exp. Date"
      width="165">
      <template slot-scope="scope">
        {{ scope.row.expiringAt | date }}
      </template>
    </el-table-column>

    <el-table-column
      label="Featured Image"
      width="125">
      <template slot-scope="scope">
        <a
          v-if="scope.row.image"
          :href="`/files/posts/${scope.row.image}`"
          target="_blank">
          <el-image
            :src="`/files/posts/${scope.row.image}`"
            class="featured-image"
            fit="contain">
            <div slot="error" class="image-slot with-icon">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </a>
        <el-image
          v-else
          class="featured-image">
          <div slot="error" class="image-slot">No image</div>          
        </el-image>
      </template>
    </el-table-column>

    <el-table-column label="Headline">
      <template slot-scope="scope">
        <router-link :to="{ name: 'PostView', params: { id: scope.row.id } }">
          <b>{{ scope.row.headline }}</b>
        </router-link>
      </template>
    </el-table-column>

    <el-table-column
      label="Status"
      width="100">
      <template slot-scope="scope">
        <el-tag
          :type="statusColors[scope.row.status]"
          effect="dark"
          size="mini">
          {{ scope.row.status | postStatus }}
        </el-tag>
      </template>
    </el-table-column>

    <el-table-column
      label="Auto-Published"
      width="120">
      <template slot-scope="scope">
        {{ scope.row.autoPublished ? 'Yes' : 'No' }}
      </template>
    </el-table-column>

    <el-table-column
      label="Post Classification"
      width="140">
      <template slot-scope="scope">
        {{ scope.row.classification | classification }}
      </template>
    </el-table-column>

    <el-table-column
      label="Reviewed At"
      width="165">
      <template slot-scope="scope">
        <template v-if="scope.row.reviewedAt">
          {{ scope.row.reviewedAt | date }}
        </template>
        <template v-else>
          N/A
        </template>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    posts: {
      type: Array,
      required: true
    },

    hideProfileId: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    statusColors () {
      return window.postStatusColors
    }
  }
}
</script>

<style lang="scss" scoped>
.featured-image {
  width: 80px;
  height: 80px;
}
</style>
