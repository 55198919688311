<template>
  <form-item
    :label="label"
    :validator="validator"
    class="w-full">
    <el-input
      v-if="!showAsText"
      v-model="val"
      v-bind="options"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :show-word-limit="!!maxlength"
      :min="min"
      :max="max"
      :disabled="disabled"
      @blur="$emit('blur')"
      @keyup.native="$emit('keyup', $event)"
      @keyup.enter.native="$emit('enter')"
      @change="$emit('change', $event)" />
    <span
      v-else
      class="textual">
      {{ val || 'N/A' }}
    </span>
  </form-item>
</template>

<script>
import inputMixin from '../../input-mixin.js'

export default {
  mixins: [inputMixin],

  props: {
    type: {
      type: String,
      default: 'text'
    },

    maxlength: {
      type: [Number, String],
      default: null
    },

    min: {
      type: [Number, String],
      default: null
    },

    max: {
      type: [Number, String],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
  .textual {
    display: block;
    min-height: 20px;
    line-height: 20px;
    padding: 10px 0;
    font-weight: bold;
  }
</style>