import Vue from 'vue'
import VueRouter from 'vue-router'
import { Loading } from 'element-ui'

import store from '../store'
import routes from './routes'

Vue.use(VueRouter)

let loader = null
const user = window.appData.currentUser
store.commit('user/setUser', user)
store.commit('system/setSingle', window.appData.system)

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

router.beforeEach((to, from, next) => {
  loader = Loading.service({ fullscreen: true })
  if (to.matched.some(record => record.meta.noAuth) && user) next({ name: 'Dashboard' })
  to.matched.some(record => record.meta.auth) && !user ? next({ name: 'Login' }) : next()
  window.scrollTo({ top: 0 })
})

router.afterEach(_ => {
  loader.close()
})

export default router
