var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form-item",
    {
      staticClass: "w-full",
      attrs: { label: _vm.label, validator: _vm.validator }
    },
    [
      _c("el-input", {
        attrs: {
          type: "textarea",
          rows: _vm.rows,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          maxlength: _vm.maxlength,
          "show-word-limit": !!_vm.maxlength,
          resize: _vm.resize,
          autosize: ""
        },
        on: {
          blur: function($event) {
            return _vm.$emit("blur")
          }
        },
        model: {
          value: _vm.val,
          callback: function($$v) {
            _vm.val = $$v
          },
          expression: "val"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }