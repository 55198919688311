var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "portal",
        { attrs: { to: "actions" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: {
                click: function($event) {
                  _vm.manage = true
                }
              }
            },
            [_vm._v("\n      Add new Post\n    ")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "default", icon: "el-icon-s-operation" },
              on: {
                click: function($event) {
                  _vm.openFilters = true
                }
              }
            },
            [_vm._v("\n      Filters\n    ")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "applied-filters" },
        _vm._l(_vm.appliedFilters, function(filter) {
          return _c(
            "el-tag",
            {
              key: filter.key,
              staticClass: "applied-filter",
              attrs: {
                type: "primary",
                effect: "dark",
                size: "small",
                closable: ""
              },
              on: {
                close: function($event) {
                  return _vm.removeFilter(filter)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(filter.text) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Posts" } },
        [_c("posts-table", { attrs: { posts: _vm.filteredPosts } })],
        1
      ),
      _vm._v(" "),
      _vm.manage
        ? _c("manage-post", {
            on: {
              close: function($event) {
                return _vm.closeManage()
              }
            }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.openFilters
        ? _c("posts-filters", {
            attrs: { filters: _vm.filters },
            on: {
              apply: function($event) {
                return _vm.applyFilters($event)
              },
              close: function($event) {
                _vm.openFilters = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }