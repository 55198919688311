var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "page-404" }, [
    _c("img", { attrs: { src: "assets/images/404.svg" } }),
    _vm._v(" "),
    _c(
      "div",
      [
        _c("h1", { staticClass: "gn-title" }, [
          _vm._v("Oops! Page not found.")
        ]),
        _vm._v(" "),
        _c("h2", { staticClass: "gn-subtitle" }, [
          _vm._v("We can't seem to find the page you're looking for.")
        ]),
        _vm._v(" "),
        _c(
          "router-link",
          { attrs: { to: { name: "Dashboard" } } },
          [
            _c(
              "el-button",
              {
                staticClass: "button",
                attrs: { type: "primary", icon: "el-icon-back", round: "" }
              },
              [_vm._v("\n        Back to Homepage\n      ")]
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }