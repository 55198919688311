var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "auth",
    {
      attrs: {
        title: "Change Password",
        subtitle: "In order to continue, You will need set new password.",
        right: ""
      }
    },
    [
      _c(
        "el-form",
        { attrs: { model: _vm.form, "label-position": "top" } },
        [
          _vm.error
            ? _c("div", { staticClass: "textual-error" }, [
                _vm._v("\n      " + _vm._s(_vm.error) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.password,
              options: { "show-password": true },
              label: "Password",
              placeholder: "Enter password"
            },
            model: {
              value: _vm.form.password,
              callback: function($$v) {
                _vm.$set(_vm.form, "password", $$v)
              },
              expression: "form.password"
            }
          }),
          _vm._v(" "),
          _c("gn-input", {
            attrs: {
              validator: _vm.$v.form.repeatPassword,
              options: { "show-password": true },
              label: "Repeat Password",
              placeholder: "Repeat password"
            },
            model: {
              value: _vm.form.repeatPassword,
              callback: function($$v) {
                _vm.$set(_vm.form, "repeatPassword", $$v)
              },
              expression: "form.repeatPassword"
            }
          }),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "button",
              attrs: { type: "primary", loading: _vm.loading, round: "" },
              on: {
                click: function($event) {
                  return _vm.save()
                }
              }
            },
            [_vm._v("\n      Change Password\n    ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }