var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("header", { attrs: { id: "header" } }, [
    _c(
      "div",
      { staticClass: "left" },
      [
        _c("router-link", { attrs: { to: { name: "Dashboard" } } }, [
          _c("img", { staticClass: "logo", attrs: { src: _vm.logo } })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "search" }, [
          _c("i", { staticClass: "el-icon-search" }),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.search,
                expression: "search"
              }
            ],
            attrs: { type: "text", placeholder: "Start typing to search" },
            domProps: { value: _vm.search },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.search = $event.target.value
              }
            }
          })
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "right" },
      [
        _c("ul", { staticClass: "quick-action" }, [
          _c(
            "li",
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: "Offers",
                    placement: "bottom"
                  }
                },
                [_c("i", { staticClass: "el-icon-refresh" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-popover",
                {
                  ref: "popover",
                  attrs: {
                    placement: "bottom",
                    width: "500",
                    trigger: "click"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "reference",
                      fn: function() {
                        return [
                          _c(
                            "span",
                            [
                              _vm.unread
                                ? _c(
                                    "el-badge",
                                    { attrs: { value: _vm.unread } },
                                    [
                                      _c("i", {
                                        staticClass: "el-icon-chat-line-square"
                                      })
                                    ]
                                  )
                                : _c("i", {
                                    staticClass: "el-icon-chat-line-square"
                                  })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _c(
                    "div",
                    { staticClass: "messages" },
                    [
                      _vm._l(_vm.latestMessages, function(message) {
                        return _c("system-message", {
                          key: message.id,
                          attrs: { message: message }
                        })
                      }),
                      _vm._v(" "),
                      !_vm.latestMessages.length
                        ? _c("el-empty", {
                            attrs: {
                              "image-size": 50,
                              description: "There are no system messages found."
                            }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "see-all" }, [
                        _c(
                          "a",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function($event) {
                                return _vm.showAllMessages()
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  _vm.messages.length > _vm.maxMessages
                                    ? "See all"
                                    : "Inbox"
                                ) +
                                "\n              "
                            )
                          ]
                        )
                      ])
                    ],
                    2
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: "Notifications",
                    placement: "bottom"
                  }
                },
                [_c("i", { staticClass: "el-icon-bell" })]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "el-tooltip",
                {
                  attrs: {
                    effect: "dark",
                    content: "Support",
                    placement: "bottom"
                  }
                },
                [_c("i", { staticClass: "el-icon-help" })]
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c(
          "el-dropdown",
          {
            attrs: { trigger: "click" },
            on: {
              command: function($event) {
                return _vm.onDropdownItemClick($event)
              }
            }
          },
          [
            _c("div", { staticClass: "profile" }, [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.user.firstName) + " " + _vm._s(_vm.user.lastName)
                )
              ]),
              _vm._v(" "),
              _c("img", {
                staticClass: "avatar",
                attrs: { src: "assets/images/avatar.png" }
              })
            ]),
            _vm._v(" "),
            _c(
              "el-dropdown-menu",
              { attrs: { slot: "dropdown" }, slot: "dropdown" },
              [
                _c("el-dropdown-item", { attrs: { command: "profile" } }, [
                  _vm._v("Profile")
                ]),
                _vm._v(" "),
                _c("el-dropdown-item", { attrs: { command: "settings" } }, [
                  _vm._v("Change Password")
                ]),
                _vm._v(" "),
                _c(
                  "el-dropdown-item",
                  { attrs: { command: "logout", divided: "" } },
                  [_vm._v("\n          Logout\n        ")]
                )
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "dark-mode-button" }, [
          _c("i", {
            class: _vm.darkMode ? "el-icon-open" : "el-icon-turn-off",
            on: {
              click: function($event) {
                return _vm.toggleDarkMode()
              }
            }
          })
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }