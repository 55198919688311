var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c("label", [
          _vm._v(_vm._s(_vm.label) + ": "),
          _vm.isRequired
            ? _c("span", { staticClass: "required" }, [_vm._v("*")])
            : _vm._e()
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { class: { error: _vm.hasError } }, [_vm._t("default")], 2),
    _vm._v(" "),
    _vm.errors.length && _vm.validator
      ? _c(
          "span",
          { staticClass: "errors" },
          _vm._l(_vm.errors, function(error) {
            return _c(
              "span",
              { key: error, staticClass: "help-block text-danger" },
              [_vm._v(_vm._s(error))]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }