var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    [
      !_vm.user || _vm.user.status !== "REGISTERED"
        ? [
            _c("sidebar"),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { id: "page" } },
              [
                _c("main-header"),
                _vm._v(" "),
                _c("page-info"),
                _vm._v(" "),
                _c("section", [_c("router-view")], 1)
              ],
              1
            )
          ]
        : _c("change-password")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }