import axios from 'axios'

const state = {
  user: null
}

const getters = {
  isLoggedIn: (state) => !!state.user
}

const actions = {
  async login (context, payload) {
    try {
      const response = await axios.post('/login', payload)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async register (context, payload) {
    try {
      const response = await axios.post('/register', payload)
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  async logout () {
    try {
      const response = await axios.post('/logout')
      return response.data
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

const mutations = {
  setUser (state, user) {
    state.user = user
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}