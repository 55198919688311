var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c(
            "el-col",
            { attrs: { span: 6 } },
            [
              _vm.documents.length && _vm.canSee
                ? _c("documents", { attrs: { documents: _vm.documents } })
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-col",
            { attrs: { span: 18 } },
            [
              _c("profile-detailed-info", {
                attrs: { customer: _vm.customer }
              }),
              _vm._v(" "),
              _vm.user.admin && _vm.customer.inVerification
                ? _c("panel", [
                    _c(
                      "div",
                      { staticClass: "items-center" },
                      [
                        _c(
                          "el-popconfirm",
                          {
                            attrs: {
                              "confirm-button-text": "Yes",
                              "cancel-button-text": "No",
                              title:
                                "Are you sure you want to approve this profile?"
                            },
                            on: {
                              confirm: function($event) {
                                return _vm.approve()
                              }
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: {
                                  slot: "reference",
                                  type: "success",
                                  size: "small",
                                  icon: "el-icon-check"
                                },
                                slot: "reference"
                              },
                              [_vm._v("\n              Approve\n            ")]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-button",
                          {
                            staticClass: "ml-10",
                            attrs: {
                              type: "danger",
                              size: "small",
                              icon: "el-icon-close"
                            },
                            on: {
                              click: function($event) {
                                return _vm.beforeDisapprove()
                              }
                            }
                          },
                          [_vm._v("\n            Reject\n          ")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "gn-drawer",
        {
          attrs: {
            title: "Reject Profile",
            size: 500,
            "before-close": function() {
              return (_vm.disapprovalReasonDrawer = false)
            }
          },
          on: {
            closed: function($event) {
              _vm.disapprovalReasonDrawer = false
            }
          },
          scopedSlots: _vm._u([
            {
              key: "footer",
              fn: function() {
                return [
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      attrs: { disabled: _vm.loading.verificationButtons },
                      on: {
                        click: function($event) {
                          _vm.disapprovalReasonDrawer = false
                        }
                      }
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "el-button",
                    {
                      staticClass: "button",
                      attrs: {
                        type: "danger",
                        loading: _vm.loading.verificationButtons
                      },
                      on: {
                        click: function($event) {
                          return _vm.disapprove()
                        }
                      }
                    },
                    [_vm._v("\n        Reject\n      ")]
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.disapprovalReasonDrawer,
            callback: function($$v) {
              _vm.disapprovalReasonDrawer = $$v
            },
            expression: "disapprovalReasonDrawer"
          }
        },
        [
          _c(
            "el-form",
            { attrs: { "label-position": "top" } },
            [
              _c("gn-textarea", {
                attrs: {
                  validator: _vm.$v.disapprovalReason,
                  maxlength: "1000",
                  label: "Rejecting reason",
                  placeholder: "Enter Rejecting reason"
                },
                model: {
                  value: _vm.disapprovalReason,
                  callback: function($$v) {
                    _vm.disapprovalReason = $$v
                  },
                  expression: "disapprovalReason"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }