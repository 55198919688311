var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "panel",
        { attrs: { title: "Profile Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.reg_email,
                      options: _vm.yesNoOptions,
                      "show-as-text": !_vm.edit,
                      label: "Send Registration Email"
                    },
                    model: {
                      value: _vm.form.reg_email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "reg_email", $$v)
                      },
                      expression: "form.reg_email"
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.welcome_email,
                      options: _vm.yesNoOptions,
                      "show-as-text": !_vm.edit,
                      label: "Send Verification Email"
                    },
                    model: {
                      value: _vm.form.welcome_email,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "welcome_email", $$v)
                      },
                      expression: "form.welcome_email"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Posts Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.post_auto_push,
                      options: _vm.autoPublishOptions,
                      "show-as-text": !_vm.edit,
                      label: "Post Auto-Publish"
                    },
                    model: {
                      value: _vm.form.post_auto_push,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "post_auto_push", $$v)
                      },
                      expression: "form.post_auto_push"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Offers Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.offer_auto_push,
                      options: _vm.autoPublishOptions,
                      "show-as-text": !_vm.edit,
                      label: "Offer Auto-Publish"
                    },
                    model: {
                      value: _vm.form.offer_auto_push,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "offer_auto_push", $$v)
                      },
                      expression: "form.offer_auto_push"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "panel",
        { attrs: { title: "Trades Settings" } },
        [
          _c(
            "el-row",
            { attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c("gn-select", {
                    attrs: {
                      validator: _vm.$v.form.trade_auto_push,
                      options: _vm.autoPublishOptions.filter(function(option) {
                        return option.id !== "VERIFIED_ONLY"
                      }),
                      "show-as-text": !_vm.edit,
                      label: "Trade Auto-Publish"
                    },
                    model: {
                      value: _vm.form.trade_auto_push,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "trade_auto_push", $$v)
                      },
                      expression: "form.trade_auto_push"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "items-center" },
        [
          !_vm.edit
            ? _c(
                "el-button",
                {
                  staticClass: "save",
                  attrs: {
                    type: "primary",
                    icon: "el-icon-edit",
                    loading: _vm.loading
                  },
                  on: {
                    click: function($event) {
                      _vm.edit = true
                    }
                  }
                },
                [_vm._v("\n      Update\n    ")]
              )
            : [
                _c(
                  "el-button",
                  {
                    staticClass: "save",
                    attrs: { type: "white", icon: "el-icon-close" },
                    on: {
                      click: function($event) {
                        return _vm.cancel()
                      }
                    }
                  },
                  [_vm._v("\n        Cancel\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    staticClass: "save",
                    attrs: {
                      type: "primary",
                      icon: "el-icon-edit",
                      loading: _vm.loading
                    },
                    on: {
                      click: function($event) {
                        return _vm.save()
                      }
                    }
                  },
                  [_vm._v("\n        Save\n      ")]
                )
              ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }